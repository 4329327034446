import {
  FieldObject,
  FormFlowConfig,
  FormFlowConfigResult,
  SelectedBillingAddress
} from '../../../types/registrationCheckout';
import { simCardType } from '../../../config/simcard-types.js';
import isMobileSharePlan from '../../../helpers/is-mobile-share-plan';

export const getFormConfigForExistingNewFlow = ({
  device,
  selectedPlan,
  isCis,
  isCISMyInfoFlow = false,
  isNric,
  eBill,
  simType,
  hasAccessories
}: FormFlowConfig): FormFlowConfigResult => {
  const isEsim = simType === simCardType.simTypeESim;
  const showStaffUploadDocument = !!isCis && !isCISMyInfoFlow;
  const showKYCUploadDocument =
    !device && !isMobileSharePlan(selectedPlan) && !isCISMyInfoFlow && !isEsim;
  const hideNricFIN = (device && isNric) || (!device && isEsim);
  const showNricFIN =
    !hideNricFIN && !isMobileSharePlan(selectedPlan) && !isCISMyInfoFlow;
  const showBillingPref = !eBill;
  const showExistingBillingAddress = true;
  const byPassFulfilment = !device && isEsim && !hasAccessories;

  return {
    showStaffUploadDocument,
    showKYCUploadDocument,
    showNricFIN,
    showBillingPref,
    showExistingBillingAddress,
    byPassFulfilment
  };
};

export const getBillingAddressValuesFromStore = ({
  newBillingAddress = 0,
  billingAddress = 0
}: FieldObject): FieldObject => [
  {
    field: 'newBillingAddress',
    value: newBillingAddress,
    shouldValidate: true
  },
  {
    field: 'billingAddress',
    value: billingAddress,
    shouldValidate: true
  }
];

export const getBillingAddressValues = (): FieldObject => {
  return getBillingAddressValuesFromStore({
    newBillingAddress: SelectedBillingAddress.BillAddressDefaultValue,
    billingAddress: SelectedBillingAddress.BillAddressValidValue
  });
};

export const isNewBillingAddress = ({
  newBillingAddress = 0,
  billingAddress = 0
}: FieldObject): boolean => {
  return (
    newBillingAddress === SelectedBillingAddress.BillAddressDefaultValue &&
    billingAddress === SelectedBillingAddress.BillAddressValidValue
  );
};

export const getResetPostalValues = (): FieldObject => {
  return {
    postalAddress: null,
    submit: null
  };
};
