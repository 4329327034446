import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ProceedModal from '../../components/ProceedModal/ProceedModal';
import { useDispatch, useSelector } from 'react-redux';
import { TObject } from '../../types/registrationCheckout';
import CONSTANTS from '../../constants/checkout';
import { resetUserForCheckout } from '../../reducers/user';
import CHECKOUT_CONSTANT from '../../constants/checkout';
import { ACTION_TYPES } from '../../constants/actions';
import { authActions } from '@detox/actions';
import {
  BrowserOptions,
  useBrowserBack
} from '../useBrowserBack/useBrowserBack';
import { navigate } from 'gatsby';

export type PropTypes = {
  pageName?: string;
  onConfirmBrowseBack?: () => void;
  customPopstate?: () => void;
  preventBrowseBack?: boolean;
  callbackOnBackForwardPressed?: () => void;
};

interface TReturnType {
  renderBrowseBackModal: () => ReactNode;
  resetLoginAndCheckoutData: () => void;
  resetCheckoutError: () => void;
  pushStateForBrowseBackHandling: () => void;
  backForwardData: BrowserOptions;
}

export const useBrowseBackPopup = ({
  pageName,
  onConfirmBrowseBack,
  customPopstate,
  preventBrowseBack = false,
  callbackOnBackForwardPressed
}: PropTypes): TReturnType => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { userInformation, checkout } = useSelector((state: TObject) => ({
    checkout: state.checkout,
    userInformation: state.user?.information
  }));
  const hasPushState = useRef(false);
  const { resetWorryFreeStatus, deleteWorryFree } = authActions;
  const { isBackPressed, backForwardData } = useBrowserBack(
    callbackOnBackForwardPressed
  );

  const shouldClearLoginData = () => {
    const checkoutFlow = checkout?.checkoutFlow;
    return (
      (checkoutFlow === CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER ||
        checkoutFlow === CONSTANTS.CHECKOUT_NEW_NUMBER ||
        checkoutFlow === CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER) &&
      !userInformation?.userDetails?.loginId
    );
  };

  const pushStateForBrowseBackHandling = (isClosePopup?: boolean) => {
    if (
      preventBrowseBack &&
      shouldClearLoginData() &&
      ((!isBackPressed && !hasPushState.current) || isClosePopup)
    ) {
      window.history.pushState(
        null,
        null,
        `${window.location.origin}/${pageName}`
      );
      hasPushState.current = true;
    }
  };

  const resetLoginAndCheckoutData = async () => {
    if (shouldClearLoginData()) {
      await dispatch(deleteWorryFree());
      dispatch({
        type: CONSTANTS.CHECKOUT_RESET_WORRY_FREE
      });
      dispatch(resetUserForCheckout());
      dispatch(resetWorryFreeStatus({}));
    }
    dispatch({
      type: CHECKOUT_CONSTANT.CHECKOUT_RESET_VERIFICATION_DATA
    });
    dispatch({
      type: ACTION_TYPES.DELIVERY.CLEAR_DELIVERY
    });
    dispatch({
      type: CONSTANTS.RESET_CHECKOUT_FORM_DATA
    });
    resetCheckoutError();
  };

  const resetCheckoutError = () => {
    dispatch({
      type: CHECKOUT_CONSTANT.CHECKOUT_RESET_ERROR_SCENARIO_CHECK
    });
    dispatch({
      type: CHECKOUT_CONSTANT.CHECKOUT_CLEAR_CHECKOUT_ERROR
    });
  };
  useEffect(() => {
    if (preventBrowseBack) {
      pushStateForBrowseBackHandling();
    }
  }, []);

  useEffect(() => {
    if (preventBrowseBack) {
      window.onpopstate = () => {
        if (customPopstate) {
          customPopstate();
        } else if (shouldClearLoginData()) {
          return setIsModalOpen(true);
        }
      };
    }

    return () => {
      if (preventBrowseBack) {
        window.onpopstate = null;
      }
    };
  }, [customPopstate]);

  const renderBrowseBackModal = () => {
    return (
      <ProceedModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        proceedClicked={async () => {
          onConfirmBrowseBack && onConfirmBrowseBack();
        }}
        onClose={() => {
          pushStateForBrowseBackHandling(true);
        }}
      />
    );
  };

  return {
    renderBrowseBackModal,
    resetLoginAndCheckoutData,
    resetCheckoutError,
    pushStateForBrowseBackHandling,
    backForwardData
  };
};
