enum ERROR_CODES {
  GENERIC = 'GENERIC_ERROR'
}

export type GatsbyImage = {
  image: {
    childImageSharp: {
      gatsbyImageData: {
        images: {
          fallback: {
            src: string;
          };
        };
      };
    };
  };
};

interface ErrorMessage {
  __typename: string;
  errorCode: string;
  bccErrorCode: string;
  title: string;
  description: string;
  enableCTA: boolean;
  ctaActionType: string;
  ctaType: string;
  ctaText: string;
  ctaURL: string;
  footerLinks: FooterLink[];
}

interface FooterLink {
  label: string;
  link: string;
  type: string;
}

// global any to prevent warning all over the use
type APP_TYPE_ANY = any;

interface KeyValue {
  [key: string]: APP_TYPE_ANY;
}

export interface AppState extends KeyValue {
  loading?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  errorCode?: string;
  error?: boolean;
  errorMessage?: string;
  errorTitle?: string;
  skeletonLoading?: boolean;
  allowReload?: boolean;
}

export type Translate = (text: string) => string;
export { FooterLink, ErrorMessage, ERROR_CODES, KeyValue, APP_TYPE_ANY };
export type Action = {
  type: string;
  value?: KeyValue;
  payload?: KeyValue;
  data?: KeyValue;
};

export type AemVariableConfig = {
  name: string;
  value: string;
};

export const EmptyFn = (): void => {
  // doesn't do anything
};

export type ArgumentObjType = Record<string, any>;

export type AtLeastOneArgument<T, K extends keyof T = keyof T> = Partial<T> &
  {
    [P in K]: Required<Pick<T, P>> & Partial<Record<Exclude<K, P>, never>>;
  }[K];

// to be used later
type FnArgumentType = AtLeastOneArgument<{
  value1: ArgumentObjType;
  value2: ArgumentObjType;
}>;

export interface SpecialPromo {
  promoCode: string;
  planIds: string[];
  boIds: string[];
}
