/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
  useReducer
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import {
  addonsActions,
  orderActions,
  userActions,
  api,
  config
} from '@detox/actions';
import {
  Grid,
  Row,
  Column,
  Spacing,
  Text,
  Notification,
  TextLink,
  Tabs,
  useDevice
} from '@dls/web';
import { trans as t } from '../../helpers/localisation';

import LoadingOverlayComponent from '../LoadingOverlay';
import AddonCharacteristicModal from './AddonCharacteristicModal';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { flattenNodes, getAemConfig, isTagged } from '../../helpers/common';
import isAddonGroup from '../../helpers/is-addon-group';
import has5GAddon from '../../helpers/has-5g-addon';
import {
  addonMcAfeeTripleCheck,
  bundleMcAfeeCheck,
  extractAddonsWithCategory,
  getAddRemoveBillingOfferFlags,
  getAddonsCartData,
  getCharacteristicConfig,
  updateAddons
} from './addon-helper';
import { planTagsConfig } from '../../config/plan-group-config';
import { navigation } from '../../middlewares/navigation-constants';

import {
  FEATURE_FLAG_ENABLE_5GSA,
  FEATURE_FLAG_OFFER_TYPE_SIMONLY,
  FEATURE_FLAG_SPECIAL_PROMO
} from '../../types/featureFlag.types';
import isReContractAndWithNoSim from '../../helpers/is-re-contract-and-with-no-sim';

import { FLOW } from '../../types/plan.types';
import AddonModal from './AddonModal';
import SelectSimTypeModal from './SelectSimTypeModal';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { CHOOSE_SIM_TYPE, SimTypeId } from '../../constants/choose-sim-type';
import { simCardType } from '../../config/simcard-types';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import AppleCareAddonModal from './AddAddonModals/AppleCareAddonModal';
import AddAddonModal from './AddAddonModals/AddAddonModal';

import { getIfMobileShare, getIfSimOnly } from '../../selectors';
import StickyCartSummary from './StickyCartSummary';
import { feedbackFormUrl } from '../../config/links';
import { ADDON } from '../../constants';
import AddonsList from './AddonsList';
import AddGroupAddonsModal from './AddAddonModals/AddGroupAddonsModal';
import {
  ADDONS_STATE_ACTIONS,
  AddonModalType,
  addonsInitialState,
  addonsStateReducer
} from './addonsState';
import AddSimOnlyGroupAddonsModal from './AddAddonModals/AddSimOnlyGroupAddonsModal';
import AddonRemoveAckModal from './AddonRemoveAckModal';
import AddonActionErrors from './AddAddonModals/AddonActionErrors';
import { ACTION_TYPES } from '../../constants/actions';
import { AEM_CONFIG_KEYS } from '../../config/common';
import { CONTAINMENT_IDS } from '../../constants/addon';
import { SkeletonGrid } from '../SkeletonLoader';
import { useBrowserBack } from '../../hooks/useBrowserBack/useBrowserBack';

const { sortAddonsByGroup } = api.mcss.helpersAddons;
const { groupByArray } = api.mcss.helpers;

const is5GSAEnabled = isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_5GSA);

const contentWithLink = () => {
  return (
    <>
      {t('ADDON_GEN_ERROR_PRE')}{' '}
      <TextLink
        inline
        onClick={() => {
          window.open(feedbackFormUrl, '_blank');
        }}
        data-testid="gen-error-modal"
      >
        {t('ADDON_GERN_ERROR_LINK') as APP_TYPE_ANY}
      </TextLink>{' '}
      {t('ADDON_GERN_ERROR_POST')}
    </>
  );
};

const {
  getAddonsFromOrder,
  addAddonToOrder,
  removeAddonFromOrder,
  removeAndAddAddonFromOrder
} = addonsActions;
const { updateOrderToRunRules, addSimToOrder } = orderActions;
const { getUserInformation } = userActions;

export const Addons = ({ data }: KeyValue): ReactElement => {
  const dispatch = useDispatch();
  const {
    addons,
    order,
    plan,
    product,
    promotions,
    user,
    bridge,
    isSimOnly,
    isMobileShare
  } = useSelector((state: KeyValue) => ({
    addons: state.addons,
    order: state.order,
    plan: state.plan,
    product: state.product,
    promotions: state.promotions,
    user: state.user,
    bridge: state.bridge,
    isSimOnly: getIfSimOnly(state),
    isMobileShare: getIfMobileShare(state)
  }));
  const { isMobile } = useDevice();
  const { selectedService, cis: cisInfo, information } = user;
  const { selectedPlan } = plan;
  const { selectedProduct, selectedSimType } = product;
  const { specialPromo } = promotions;
  const [characteristicModal, setCharacteristicModal] = useState(null);
  const [showRecontractSim, setShowRecontractSim] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState<APP_TYPE_ANY>();
  const [showSecondaryPopupModal, setShowSecondaryPopupModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openUnknownErrorModal, setOpenUnknownErrorModal] = useState(false);
  const [addonsState, addonsDispatch] = useReducer(
    addonsStateReducer,
    addonsInitialState
  );
  const { addAddonData, addonModal, selectedTab, showRemoveAck } = addonsState;
  const isSpecialPromoEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_SPECIAL_PROMO
  );
  let {
    allAddon,
    allPhone: allApplePhone,
    allPlanGroup,
    allVariableConfig
  } = data;
  const {
    allAddonGroup,
    addonSimOnlyPlan,
    simCardOptions,
    allChooseSimOptions
  } = data;
  allAddon = useMemo(() => flattenNodes(allAddon), [allAddon]);
  // We only need Apple branded phones for AppleCare related addons
  allApplePhone = useMemo(() => flattenNodes(allApplePhone), [allApplePhone]);
  allPlanGroup = useMemo(() => flattenNodes(allPlanGroup), [allPlanGroup]);
  allVariableConfig = useMemo(() => flattenNodes(allVariableConfig), [
    allVariableConfig
  ]);
  const sopUsageName = useMemo(
    () =>
      getAemConfig(
        allVariableConfig,
        AEM_CONFIG_KEYS.DETOX_ADDONS_SOP_GROUP_NAME
      ),
    [allVariableConfig]
  );

  const chooseSIMOptions = useMemo(() => flattenNodes(allChooseSimOptions), [
    allChooseSimOptions
  ]);
  const flowType = cisInfo?.information ? FLOW.CIS : FLOW.RES;

  const userContact = information?.clientContext?.contact;
  const infoCustomerId = information?.clientContext?.customers?.[0]?.customerId;

  const appleCareAddons = useMemo(
    () =>
      allAddon.filter(({ productTitle }) => productTitle.match(/AppleCare/i)),
    [allAddon]
  );

  const { productOrder } = order;
  const {
    productOrderId,
    productOrderItemId,
    type: productOrderType,
    service: productOrderService,
    sim
  } = productOrder || {};

  const addonsCartData = getAddonsCartData({
    selectedProduct,
    selectedPlan,
    isSimOnly,
    isMobileShare
  });

  const { renderAmendedErrorPopup } = useAmendedTab();

  //Check if order includes a 5G plan or a 5G VAS
  const isHaving5gPlanOr5gVas = ({ selectedPlan, addons }) => {
    const { availableAddons, selectedAddons } = addons;
    return (
      isTagged(selectedPlan?.tags, planTagsConfig['5g-speed'].tagName) ||
      has5GAddon(availableAddons, selectedAddons)
    );
  };

  const _isHaving5gPlanOr5gVas = isHaving5gPlanOr5gVas({
    selectedPlan,
    addons
  });

  // Determine if the customer's existing SIM card is still on 4G and the new order is having a 5G SIM
  const is4gTo5gSimChange = sim && !sim.is5GSim && _isHaving5gPlanOr5gVas;
  const simCardOption = is4gTo5gSimChange
    ? simCardOptions.changeSIM4GTo5G
    : simCardOptions.changeSIM;

  const isLoading = addons.loading || order.loading || bridge?.loading;

  const showFullPageError =
    !isLoading && addons.error && !addons.availableAddons;

  const handleRefresh = useCallback(async () => {
    if (productOrderItemId && information) {
      const productOrderHasDevice = Boolean(selectedProduct);
      const customerId = productOrderService?.customerId;
      let addonsForSelectedDevice;

      if (productOrderHasDevice) {
        addonsForSelectedDevice = allAddon.filter(
          ({ phoneList, flowType: addOnFlowType }) => {
            /**
             * If the addon is for a device, it must contain the selected device
             * Allow addons that are not supposed to have a device list.
             */
            const isAddonForFlow =
              !addOnFlowType?.length || addOnFlowType?.includes(flowType);
            if (phoneList?.length === 0) return isAddonForFlow;

            const selectedDeviceGroupId = selectedProduct.groupId;
            return phoneList?.includes(selectedDeviceGroupId) && isAddonForFlow;
          }
        );
      } else {
        const { data, sms, talktime } = addonSimOnlyPlan;

        const dataIds = data.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const talkTimeIds = talktime.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));
        const smsIds = sms.map(({ addonId, quantity }) => ({
          productTitle: quantity,
          productSpecContainmentID: addonId,
          simOnlyAddon: true
        }));

        /**
         * Filter all AEM addons that match the selected device only.
         */
        addonsForSelectedDevice = allAddon.concat([
          ...dataIds,
          ...smsIds,
          ...talkTimeIds
        ]);

        // If device is not selected, skip addons with requiredDevice=true.
        addonsForSelectedDevice = addonsForSelectedDevice.filter(
          addon => !addon.requiredDevice
        );
      }
      const isOrderActionCalled =
        addons.orderActionAttributes &&
        productOrderItemId === addons.orderActionAttributes?.productOrderItemId;
      const userInfoData = userContact
        ? {
            ...userContact,
            contactId: infoCustomerId,
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          }
        : {
            selectedAddonsPayload: false,
            tradeInProductId: CONTAINMENT_IDS.TRADE_IN
          };
      await (dispatch(
        getAddonsFromOrder({
          devices: allApplePhone,
          addons: appleCareAddons,
          productOrderItemId,
          aemAddonsList: addonsForSelectedDevice,
          customerId,
          productOrderId,
          orderActionsPayload: !isOrderActionCalled ? userInfoData : null,
          bundleAddonsIds: ADDON.BUNDLE_ADDON_IDS,
          containmentIds: ADDON.CONTAINMENT_IDS,
          ...(specialPromo &&
            isSpecialPromoEnabled && {
              specialPromoCode: specialPromo?.promoCode
            })
        })
      ) as APP_TYPE_ANY);
    }
  }, [
    addonSimOnlyPlan,
    allAddon,
    allApplePhone,
    appleCareAddons,
    getAddonsFromOrder,
    productOrderItemId,
    productOrderService,
    productOrderId,
    information
  ]);

  useBrowserBack();

  useEffect(() => {
    if (!productOrder) {
      navigate('/', { replace: true });
    }
  }, [productOrder]);

  /* refresh addon data if anything is changed */
  useEffect(() => {
    if (addons.modified) {
      handleRefresh();
    }
  }, [addons.modified]);

  useEffect(() => {
    if (addons?.error?.length) {
      setOpenErrorModal(true);
    }

    const isAddonsLoading = addons.loading || order.loading || bridge?.loading;
    const modalError =
      !isAddonsLoading &&
      addons.error &&
      !addons?.error?.length &&
      !!addons.availableAddons;

    if (modalError && !openUnknownErrorModal) {
      setOpenUnknownErrorModal(true);
    } else {
      setOpenUnknownErrorModal(false);
    }
  }, [addons?.error, addons.loading, order.loading, bridge?.loading]);

  useEffect(() => {
    if (!information) {
      dispatch(getUserInformation());
    }
  }, [information]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh, information]);

  const addAddonToTheOrder = async (addon, additionalProps = {}) => {
    const cID = addon?.productSpecPricing?.childPricingSchema.id;
    // mcafee bundle handling
    const { bundleHandlingNeeded = false, bundledAddon } = bundleMcAfeeCheck(
      cID,
      addons.bundleAddonsData
    );

    if (bundleHandlingNeeded) {
      await (dispatch(
        removeAndAddAddonFromOrder({
          addonToRemove: bundledAddon,
          addonToAdd: addon,
          productOrderItemId,
          ...additionalProps
        })
      ) as APP_TYPE_ANY);
    } else {
      await (dispatch(
        addAddonToOrder({
          addon,
          productOrderItemId,
          ...additionalProps
        })
      ) as APP_TYPE_ANY);
    }
  };

  const handleAddSimOnlyAddon = addon => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    const addonToAdd = addons.availableAddons[addon.addonId];
    addAddonToTheOrder(addonToAdd);
  };

  const handleRemoveSimOnlyAddon = async addon => {
    const addonToRemove = addons.availableAddons[addon.addonId];

    await (dispatch(
      removeAddonFromOrder({
        addon: addonToRemove,
        productOrderItemId
      })
    ) as APP_TYPE_ANY);
  };

  const handleGoToNextPage = async () => {
    const { productId } = productOrder;
    const { promoOrderDetails } = promotions;
    const isOfferTypeSimOnlyEnabled = isFeatureFlagEnabled(
      FEATURE_FLAG_OFFER_TYPE_SIMONLY
    );
    try {
      await (dispatch(
        updateOrderToRunRules({
          productId,
          productOrderItemId,
          selectedAddonIds: addons.selectedAddons,
          promoOrderDetails: {
            ...promoOrderDetails,
            sourcePlan: selectedService?.offeringId
          },
          isSimOnlyPlan: isSimOnly && isOfferTypeSimOnlyEnabled,
          ...(specialPromo &&
            isSpecialPromoEnabled && {
              specialPromoCode: specialPromo?.promoCode
            })
        } as APP_TYPE_ANY)
      ) as APP_TYPE_ANY);
    } catch (e) {
      return;
    }

    return navigate(`/${navigation.SHOPPING_CART_PAGE}`);
  };

  const handleReviewCartWithPreselection = async () => {
    if (
      selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM ||
      selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
    ) {
      await handleRecontractWithSim();
    } else if (selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_noSIM) {
      await handleRecontractWithOutSim();
    }

    return await handleGoToNextPage();
  };

  const handleGo = async () => {
    // If we are recontracting but do not have a sim card in the order,
    // we ask the user if they want a sim card

    if (isReContractAndWithNoSim(productOrder)) {
      if (!is4gTo5gSimChange) {
        // prevent showing the SIM modal as user already made a selection
        return await handleReviewCartWithPreselection();
      }

      if (
        is4gTo5gSimChange &&
        selectedSimType !== CHOOSE_SIM_TYPE.SIM_TYPE_noSIM
      ) {
        return await handleReviewCartWithPreselection();
      }

      if (simCardOption.changeSIMOptionEnabled) {
        // Showing Popup for New Sim Card Options
        return setShowRecontractSim(true);
      } else {
        // No Popup for New Sim Card Options
        setShowRecontractSim(false);
        return await handleGoToNextPage();
      }
    } else {
      return await handleGoToNextPage();
    }
  };

  const handleAddAppleCare = (values: KeyValue) => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    if (values?.email) {
      addAddonToTheOrder(selectedAddon, { appleCareEmail: values.email });
    }
  };

  const handleCharacteristicModal = async (
    changedCharacteristic,
    addonFromSelection = null,
    isAppleCare = false,
    addonToRemove = null
  ) => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    const selectedAddonForChar = addonFromSelection || selectedAddon;
    const addonDataWithChar = isAppleCare
      ? changedCharacteristic
      : { changedCharacteristic };

    if (addonToRemove) {
      await (dispatch(
        removeAndAddAddonFromOrder({
          addonToRemove: addonToRemove,
          addonToAdd: selectedAddonForChar,
          productOrderItemId,
          ...addonDataWithChar,
          ...getAddRemoveBillingOfferFlags(selectedAddonForChar, addonToRemove)
        })
      ) as APP_TYPE_ANY);

      // await handleRemove(addonToRemove);
    } else {
      addAddonToTheOrder(selectedAddonForChar, addonDataWithChar);
    }
  };

  const handleAddFamilyPlan = () => {
    setShowSecondaryPopupModal(false);
    addAddonToTheOrder(selectedAddon);
  };

  const handleLearnMore = (
    addonData,
    isGroupItem = false,
    isSimOnlyAddon = false
  ): void => {
    let modalType: AddonModalType = 'standaloneAddon';

    setSelectedAddon(null);
    if (isGroupItem && !isSimOnlyAddon) {
      modalType = 'groupAddon';
    } else if (!isGroupItem && isSimOnlyAddon) {
      modalType = 'sopGroupAddon';
    } else {
      setSelectedAddon(addonData);
    }

    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.SET_MODAL_DATA,
      payload: {
        addAddonData: addonData,
        modalState: true,
        modalType
      }
    });
  };

  const handleGroupAdd = (addonData: KeyValue): void => {
    handleLearnMore(addonData, true);
  };

  const handleSimOnlyAdd = (addonData: KeyValue): void => {
    handleLearnMore(addonData, false, true);
  };

  const handleAdd = addon => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    if (isAddonGroup(addon, 'APPLECARE')) {
      addonsDispatch({
        type: ADDONS_STATE_ACTIONS.SET_APPLE_CARE_MODAL_DATA
      });
      setSelectedAddon(addon);
      return;
    }

    if (addon?.addonPopupMessage) {
      setShowSecondaryPopupModal(true);
      setSelectedAddon(addon);
      return;
    }

    /* prompts user input, this will be in 1.46 */
    /* For FREE MBB, pop up will be disabled. */
    const { characteristicConfig } = getCharacteristicConfig(addon);

    if (characteristicConfig) {
      setCharacteristicModal(characteristicConfig);
      setSelectedAddon(addon);
      return;
    }

    addAddonToTheOrder(addon);
  };

  const handleRemove = async (addon, removeAck = false) => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    const addonsCID = addon.productSpecPricing?.childPricingSchema?.id || '';

    // adding ack modal for specific addons
    // REMOVE_ACK_ADDONS is hardcoded as constants as there are no more req currently
    const isPresent = Object.values(ADDON.REMOVE_ACK_ADDONS).includes(
      addonsCID
    );
    const showAck = addonsCID && isPresent && !removeAck;

    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.SET_REMOVE_ACK_MODAL,
      payload: showAck ? addon : null
    });

    if (!showAck) {
      // triple protect handling
      const mcafeeTriple = addonMcAfeeTripleCheck(addonsCID);

      await (dispatch(
        removeAddonFromOrder({
          addon,
          productOrderItemId,
          removeWithBillingProduct: Boolean(mcafeeTriple)
        })
      ) as APP_TYPE_ANY);
    }
  };

  const handleRemoveAndAdd = async (
    addonToRemove,
    addonToAdd
  ): Promise<void> => {
    addonsDispatch({
      type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
    });

    await (dispatch(
      removeAndAddAddonFromOrder({
        addonToRemove: addonToRemove,
        addonToAdd: addonToAdd,
        productOrderItemId,
        ...getAddRemoveBillingOfferFlags(addonToAdd, addonToRemove)
      })
    ) as APP_TYPE_ANY);
  };

  const handleRecontractWithSim = async (selected4Gto5GSim?: SimTypeId) => {
    const { sim } = productOrder;
    const { productId } = sim;
    let selectedSimTypeValue = selectedSimType;
    setShowRecontractSim(false);
    if (selected4Gto5GSim) {
      selectedSimTypeValue = selected4Gto5GSim;
    }

    const simType5GValue =
      selectedSimTypeValue === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
        ? simCardType.simTypeESim
        : config.simType5G;
    const simTypeValue = is5GSAEnabled ? simType5GValue : config.simType4G;

    await (dispatch(
      addSimToOrder({
        productOrderItemId,
        simProductId: productId,
        simType: simTypeValue
      })
    ) as APP_TYPE_ANY);
  };

  const handleRecontractWithOutSim = async () => {
    setShowRecontractSim(false);
  };

  const handleReviewCart = async (selected4Gto5GSim?: SimTypeId) => {
    is4gTo5gSimChange
      ? await handleRecontractWithSim(selected4Gto5GSim)
      : await handleRecontractWithOutSim();

    return await handleGoToNextPage();
  };

  // We form another object from which we query add on group information from.
  // Key = group name
  // Value = information for that group, like setupFee
  // We calculate this value only once
  const addonGroupInfoLookup = useMemo(
    () =>
      allAddonGroup.edges.reduce((accum, cur) => {
        accum[cur.node.displayName] = cur.node;
        return accum;
      }, {}),
    [allAddonGroup.edges]
  );

  // We form an object from which we can query from.
  // Key = group name
  // Value = array of addons belonging to that group
  // We calculate this value only when availableAddons change
  //
  const addonsSortedByGroupName = useMemo(() => {
    const { availableAddons, selectedAddonsBo } = addons;
    if (availableAddons) {
      let result = [];
      const updatedAvailableAddons = updateAddons(
        availableAddons,
        selectedAddonsBo,
        {
          orderType: productOrderType,
          selectedPlan: selectedPlan
        }
      );

      const sequence = groupByArray(allAddon, 'groupName', 'productTitle').map(
        item => item.key
      );
      result = sortAddonsByGroup(updatedAvailableAddons, sequence);

      const addonsWithCategory = result.map(addonsSortedByGroup => {
        const categoryId = addonsSortedByGroup.addons?.[0]?.categoryId || '';
        const categorySequence =
          addonsSortedByGroup.addons?.[0]?.categorySequence || 0;

        return {
          ...addonsSortedByGroup,
          categoryId,
          categorySequence: +categorySequence
        };
      });

      return addonsWithCategory;
    }
    return [];
  }, [addons, allAddon, order]);

  const handleProceedClick = () => handleGo();

  if (showFullPageError) {
    return <AddonActionErrors />;
  }

  // moving out the props as a separate var to avoid type errors from DLS2 comps
  // TODO: remove APP_TYPE_ANY casting
  const addonsErrorModalProps = {
    isModalOpen: openErrorModal,
    modaltitle: t('PRODUCT_CONFIGURATION_ERROR_TITLE') as string,
    modalContent: t('PRODUCT_CONFIGURATION_ERROR_DESCRIPTION') as string,
    modalItems: addons?.error,
    secondaryType: false,
    onClose: () => {
      setOpenErrorModal(false);
    }
  } as APP_TYPE_ANY;

  const showSecondaryPopupModalProps = {
    isModalOpen: showSecondaryPopupModal,
    disableClose: false,
    modaltitle: selectedAddon?.addonPopupTitle || '',
    modalContent: selectedAddon?.addonPopupMessage || '',
    ctaText: selectedAddon?.addonPopupButtonLabel || '',
    secondaryType: true,
    onConfirm: handleAddFamilyPlan,
    onClose: () => setShowSecondaryPopupModal(false)
  } as APP_TYPE_ANY;

  const addonsWithCategory =
    extractAddonsWithCategory({
      assignedAddons: addonsSortedByGroupName,
      configuredAddons: addonGroupInfoLookup,
      isSimOnly: isSimOnly,
      addonSimOnlyPlan: addonSimOnlyPlan,
      selectedAddons: addons.selectedAddons,
      usageKeyValue: sopUsageName
    }) || {};

  const noOfTabs = Boolean(Object.keys(addonsWithCategory)?.length > 1);
  const addonsTabs = Object.keys(addonsWithCategory).map(addonTab => ({
    title: `${addonTab} (${addonsWithCategory[addonTab].length})`
  }));

  const addonTabIndexes = Object.keys(
    addonsWithCategory
  ).map((addonTab, addonTabIndex) => ({ [addonTabIndex]: addonTab }));
  const tabIndexAddons = Object.assign({}, ...addonTabIndexes);

  const selectedAddonsGroupData =
    addonsWithCategory[tabIndexAddons[selectedTab]] || [];

  const renderAddAddonModals = () => {
    const onCloseHandler = () => {
      addonsDispatch({
        type: ADDONS_STATE_ACTIONS.RESET_MODAL_DATA
      });
    };

    const addonModalProps = {
      onClose: onCloseHandler,
      addonData: addAddonData,
      onAdd: handleAdd,
      onRemove: handleRemove,
      onAddWithCharacteristics: handleCharacteristicModal
    };

    return (
      <>
        <AppleCareAddonModal
          isModalOpen={addonModal.appleCareAddon}
          {...addonModalProps}
          onClose={handleAddAppleCare}
          onRemoveAndAdd={handleRemoveAndAdd}
        />

        <AddAddonModal
          isAddAddonOpen={addonModal.standaloneAddon}
          {...addonModalProps}
        />

        <AddGroupAddonsModal
          isAddAddonOpen={addonModal.groupAddon}
          {...addonModalProps}
          onRemoveAndAdd={handleRemoveAndAdd}
        />

        <AddSimOnlyGroupAddonsModal
          isAddAddonOpen={addonModal.sopGroupAddon}
          {...addonModalProps}
          onAdd={handleAddSimOnlyAddon}
        />
      </>
    );
  };

  return (
    <Fragment>
      <StickyCartSummary
        {...(addonsCartData as APP_TYPE_ANY)}
        onCtaClick={handleProceedClick}
      />

      {!addons.isSkeletonLoading && isLoading && <LoadingOverlayComponent />}
      {characteristicModal && (
        <AddonCharacteristicModal
          {...characteristicModal}
          onConfirm={handleCharacteristicModal}
          onClose={() => setCharacteristicModal(null)}
        />
      )}

      {!!showRemoveAck && (
        <AddonRemoveAckModal
          addon={showRemoveAck}
          isModalOpen={!!showRemoveAck}
          onClose={() => {
            addonsDispatch({
              type: ADDONS_STATE_ACTIONS.SET_REMOVE_ACK_MODAL,
              payload: null
            });
          }}
          onProceed={addon => {
            handleRemove(addon, true);
          }}
        />
      )}

      <SelectSimTypeModal
        title={simCardOption.title}
        subTitle={
          _isHaving5gPlanOr5gVas && is5GSAEnabled
            ? simCardOption.blueNotificationMessage
            : ''
        }
        chooseSIMOptions={chooseSIMOptions}
        isModalOpen={showRecontractSim}
        selectedSimType={selectedSimType}
        selectedProduct={selectedProduct}
        onClose={() => setShowRecontractSim(false)}
        onProceed={selected4Gto5GSim => handleReviewCart(selected4Gto5GSim)}
      />

      {addons?.error?.length && (
        <div data-testid="production-configuration-error-modal">
          <AddonModal {...addonsErrorModalProps} />
        </div>
      )}

      {showSecondaryPopupModal && (
        <AddonModal {...showSecondaryPopupModalProps} />
      )}

      {openUnknownErrorModal && (
        <AddonActionErrors
          inModal
          isModalOpen={openUnknownErrorModal}
          onErrorModalClose={() => {
            dispatch({
              type: ACTION_TYPES.ADDONS.RESET_UNKNOWN_ERROR
            });
            setOpenUnknownErrorModal(false);
          }}
        />
      )}
      <Grid>
        <Row>
          <Column sm={12} md={8}>
            <Spacing top={2} bottom={6}>
              <Spacing bottom={3}>
                <Text type="pageTitle">{t('ADDON_PAGE_TITLE')}</Text>
              </Spacing>

              {order.error && (
                <Spacing bottom={2}>
                  <Notification type="alert" content={contentWithLink()} />
                </Spacing>
              )}

              {noOfTabs && (
                <Spacing bottom={3}>
                  <Tabs
                    tabs={addonsTabs}
                    selected={selectedTab}
                    onTabItemClick={(_, index) => {
                      addonsDispatch({
                        type: ADDONS_STATE_ACTIONS.SET_SELECTED_TAB,
                        payload: { selectedTab: index }
                      });
                    }}
                  />
                </Spacing>
              )}

              <SkeletonGrid
                numberOfRow={3}
                numberOfColumn={isMobile ? 1 : 3}
                isLoading={addons.isSkeletonLoading}
                itemType="Card"
                withOverlay={true}
                position={{ top: 2 }}
              >
                <AddonsList
                  addonGroups={selectedAddonsGroupData}
                  addonGroupInfoLookup={addonGroupInfoLookup}
                  onGroupAdd={handleGroupAdd}
                  onSimOnlyGroupAdd={handleSimOnlyAdd}
                  onAdd={handleAdd}
                  onRemove={handleRemove}
                  onLearnMore={handleLearnMore}
                  onSimOnlyRemove={handleRemoveSimOnlyAddon}
                />
              </SkeletonGrid>
            </Spacing>
          </Column>
        </Row>
      </Grid>
      {renderAddAddonModals()}
      {renderAmendedErrorPopup()}
    </Fragment>
  );
};

export default Addons;
