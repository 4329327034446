/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Text, Grid, Spacing } from '@dls/web';

import {
  authActions,
  ACTION_TYPES,
  orderSummaryActions,
  fulFillmentActions
} from '@detox/actions';
import { OrderConfirmation } from '@common-modules/shopping-cart';
import { getUrlParams } from '@lux/helpers';

import {
  isCashPayment,
  isPaymentSuccessFromUrlParams,
  isOrderFailed,
  isOrderSuccess,
  isOnlinePayment,
  isZeroUpfront
} from '../../../components/ThankYou/helper';
import { ListSkeletonLoader } from '../../../components/SkeletonLoader';
import HelpBox from '../../../components/HelpBox';

import { OrderSummaryState } from '../../../reducers/orderSummary';
import {
  PaymentState,
  resetPayment,
  clearBuyFlowData
} from '../../../reducers/payment';

import { useFlowCheck } from '../../../hooks/useFlowCheck/useFlowCheck';
import useUser from '../../../hooks/useUser';

import { trans as t } from '../../../helpers/localisation';
import { dataLayerPush, getAccountBillingInfo } from '../../../helpers/common';
import { mask } from '../../../helpers/pii-masker';
import {
  getOrderStatusText,
  ORDER_CONFIRMATION_LEGO
} from '../../../helpers/orderConfirmationHelper';
import { ORDER, COMMON } from '../../../constants';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import { navigation } from '../../../middlewares/navigation-constants';
import { rrpOrderConfirmationDataLayer } from '../../../helpers/dataLayerHelpers';
import { getCatalogPath } from '../../../helpers/rrpCart';

const { URL_PARAMS } = ORDER;

interface WhatsNextItem {
  title?: string;
  paragraph: string | ReactElement;
  value?: { [key: string]: APP_TYPE_ANY };
}

export const renderWhatsNext = (items: WhatsNextItem[], numbering = true) => {
  return items.map((item, index) => {
    return (
      <Spacing topBottom={0.5}>
        {item.title && (
          <Text type="boldBody">
            {numbering && `${index + 1}. `}
            {item.title}
          </Text>
        )}
        <Text type="body">{item.paragraph}</Text>
      </Spacing>
    );
  });
};

export const RrpIppOrderConfirmation = ({
  location
}: {
  location: Location;
}): ReactElement => {
  useUser();
  const dispatch = useDispatch();
  const urlParams = getUrlParams();

  const {
    auth,
    user,
    fulfillment,
    payment,
    orderSummary,
    cart,
    checkout,
    rrpCart
  } = useSelector((state: KeyValue) => ({
    auth: state.auth,
    user: state.user,
    order: state.order,
    fulfillment: state.fulfillment,
    payment: state.payment as PaymentState,
    orderSummary: state.orderSummary as OrderSummaryState,
    product: state.product,
    promotions: state.promotions,
    addons: state.addons,
    checkout: state.checkout,
    plan: state.plan,
    cart: state.cart,
    customerMyInfo: state?.customerMyInfo,
    rrpCart: state.rrpCart
  }));
  const productOrder = cart?.order?.productOrder;
  const userInfo = user?.information;
  const contact = userInfo?.clientContext?.contact;
  const customerId = userInfo?.clientContext?.customers?.[0]?.customerId;
  const isLoading =
    user.isLoading || payment?.isLoading || payment?.distributeLoading;

  const worryFreeStatus = auth?.worryFree?.status;
  const cleanWorryFree = auth?.worryFree?.otpVerified;
  const sessionToken = auth?.sessionToken;

  useFlowCheck({
    flowData: [orderSummary?.quoteFetched, urlParams[URL_PARAMS.ONEPAY_STATUS]]
  });

  useEffect(() => {
    window.sessionStorage.removeItem('det-onepay-ref-id');
  }, []);

  useEffect(() => {
    if (!sessionToken || cleanWorryFree) {
      authActions.getAuthToken({ cleanWorryFree });
    } else if (sessionToken && worryFreeStatus) {
      authActions.resetWorryFree();
    }
  }, [
    cleanWorryFree,
    worryFreeStatus,
    authActions.getAuthToken,
    authActions.resetWorryFree,
    sessionToken
  ]);

  useEffect(() => {
    if (customerId && !payment?.serviceId) {
      dispatch({
        type: ACTION_TYPES.ORDER_SUMMARY.SET_PAYMENT_STATUS,
        value: {
          orderStatus: urlParams[URL_PARAMS.ONEPAY_STATUS],
          productOrderId: productOrder?.productOrderId,
          productOrderReferenceNumber:
            productOrder?.productOrderReferenceNumber,
          orderAmount:
            orderSummary.quoteData?.checkOutPriceBreakdown
              ?.finalAmountIncludingTax,
          selectedDeliveryMode: fulfillment.delivery?.selectedDeliveryMode,
          customerId,
          serviceId: cart?.order?.mobile?.serviceId
        }
      });
      dataLayerPush(
        rrpOrderConfirmationDataLayer({
          baseParams: {
            cartItems: rrpCart?.addedItems,
            location: window.location,
            delivery: { type: fulfillment?.delivery?.selectedDeliveryMode },
            order: productOrder,
            ippDetails: rrpCart?.tempCartItem
          }
        })
      );
    }
  }, [customerId]);

  /**
   * @description: Reset when validate payment api has already been called.
   */
  useEffect(() => {
    if (payment?.isDoneVerifyPayment || isZeroUpfront(location)) {
      setTimeout(() => {
        dispatch(clearBuyFlowData());
      }, 10);
    }
  }, [payment.isDoneVerifyPayment]);

  useEffect(() => {
    // only proceed further we have success payment.
    if (userInfo?.clientContext && isPaymentSuccessFromUrlParams(location)) {
      if (isOnlinePayment(location)) {
        handleResultFromPaymentGateway();
      } else if (isCashPayment(location)) {
        handleResultFromPaymentViaCash();
      }
    }
  }, [userInfo?.clientContext]);

  const handleResultFromPaymentGateway = () => {
    const { barId, faId } = getAccountBillingInfo({
      checkout,
      delivery: fulfillment.delivery,
      userInformation: userInfo
    });

    const data = {
      contactId: contact.contactId,
      orderId: payment?.productOrderId || productOrder.productOrderId,
      customerId,
      referenceId: urlParams[URL_PARAMS.MERCHANT_REF_ID],
      amount:
        payment.orderAmount ||
        orderSummary.quoteData?.checkOutPriceBreakdown?.finalAmountIncludingTax,
      accountId: faId,
      purchaseToken: urlParams[URL_PARAMS.PAYMENT_TOKEN],
      productOrderId: payment?.productOrderId || productOrder.productOrderId,
      productOrderReferenceNumber:
        payment?.productOrderReferenceNumber ||
        productOrder.productOrderReferenceNumber,
      barId
    };

    dispatch(orderSummaryActions.verifyPayment(data));
  };

  const handleResultFromPaymentViaCash = () => {
    dispatch(
      fulFillmentActions.verifyPaymentsViaCOD({
        userInfo: {
          customerId: customerId
        },
        productOrder: productOrder,
        isOrderConfirm: true
      })
    );
  };

  const composeData = () => {
    const { email } = userInfo?.clientContext?.contact || {};
    const whatsNextItems: WhatsNextItem[] = [];

    switch (payment?.status) {
      case ORDER.STATUS.SUCCESS:
        whatsNextItems.push({
          title: t(
            'THANK_YOU_PAGE_WHAT_NEXT_TITLE_RECEIVE_ORDER_CONFIRMATION'
          ) as string,
          paragraph: t(
            'THANK_YOU_PAGE_WHAT_NEXT_RECEIVE_ORDER_CONFIRMATION_DESCRIPTION',
            { email: email }
          ) as string
        });

        whatsNextItems.push({
          title: t('THANK_YOU_PAGE_WHAT_NEXT_TITLE_TRACK_ORDER') as string,
          paragraph: (
            <>
              {t('THANK_YOU_PAGE_WHAT_NEXT_TRACK_ORDER_DESCRIPTION', {
                serviceId: payment?.serviceId && mask(payment?.serviceId)
              })}
              <Text type="link" href={ORDER.URL.SINGTEL_APP} target="_blank">
                {t('MY_SINGTEL_APP') as ReactElement}
              </Text>
            </>
          )
        });
        break;

      case ORDER.STATUS.FAILURE:
        whatsNextItems.push({
          paragraph: t('THANK_YOU_PAGE_FAILURE_LINER') as string
        });
        break;

      case ORDER.STATUS.PENDING:
        whatsNextItems.push({
          paragraph: t('THANK_YOU_PAGE_WHAT_NEXT_ORDER_PENDING_DESCRIPTION', {
            email: email
          }) as string
        });
        break;

      default:
        break;
    }

    return {
      orderNo: payment?.productOrderId,
      customerEmail: email,
      customerSupport: COMMON.THANK_YOU_PAGE_CUSTOMER_SUPPORT,
      customContent: {
        WhatNext: renderWhatsNext(whatsNextItems),
        HelpBox: <HelpBox />
      },
      ...(ORDER.STATUS.SUCCESS === payment?.status && {
        importantNote: {
          title: t('THANKYOU_PAGE_IMPT_NOTE_TITLE'),
          content: t('NOTE_CONFIRMATION_EMAIL_IN_YOUR_INBOX', {
            /* @ts-ignore */
            duration: (
              <Text
                type="boldSmallBody"
                tag="span"
                style={{ fontWeight: 'bold' }}
              >
                {t('THANKYOU_PAGE_IMPT_NOTE_CONTENT_2')}
              </Text>
            )
          })
        }
      })
    };
  };

  const resetPaymentAndReturnToRoot = () => {
    dispatch(resetPayment());
    navigate(getCatalogPath(), { replace: true });
  };

  const orderStatus = getOrderStatusText(payment);

  const backToHomeButtonText = isOrderFailed(payment)
    ? t('THANK_YOU_PAGE_FAILURE_CTA_TEXT')
    : t('THANK_YOU_PAGE_CTA_TEXT');

  if (!payment?.status) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid>
        <ListSkeletonLoader numberOfItem={3} />
      </Grid>
    );
  }

  return (
    <>
      <OrderConfirmation
        data={composeData()}
        configs={{
          orderStatus: payment?.status,
          displayMapping: [
            { itemType: ORDER_CONFIRMATION_LEGO.OrderStatus },
            { itemType: ORDER_CONFIRMATION_LEGO.OrderInfo },
            {
              itemType:
                isOrderSuccess(payment) && ORDER_CONFIRMATION_LEGO.ImportantNote
            },
            { itemType: ORDER_CONFIRMATION_LEGO.WhatNext },
            {
              itemType:
                isOrderFailed(payment) && ORDER_CONFIRMATION_LEGO.NextStep
            },
            { itemType: ORDER_CONFIRMATION_LEGO.BackHome },
            { itemType: ORDER_CONFIRMATION_LEGO.HelpBox }
          ]
        }}
        localisation={{
          ORDER_STATUS_TITLE: orderStatus.title,
          ORDER_STATUS_BODY_TEXT: orderStatus.message,
          ORDER_INFO_SUBTITLE: t('THANK_YOU_PAGE_STATUS_SUBTITLE'),
          ACTION_BUTTON_BACK_TO_HOME: backToHomeButtonText,
          ORDER_NEXT_STEP_TITLE: t('WHATS_NEXT')
        }}
        callbacks={{
          onClickBackBtn: resetPaymentAndReturnToRoot,
          onClickFeedbackBtn: (v: APP_TYPE_ANY) => {
            console.log('value', v);
          }
        }}
      />
    </>
  );
};

export default RrpIppOrderConfirmation;
