import React, { useEffect, useMemo, useState } from 'react';
import { WindowLocation } from '@reach/router';
import styled, { css } from 'styled-components';
import {
  Column,
  Grid,
  Row,
  Text,
  Tabs,
  TextLink,
  Spacing,
  withTheme,
  useTheme,
  useDevice
} from '@dls/web';
import { getUrlParams, setUrlParams } from '@lux/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { trans as t } from '../../helpers/localisation';
import useThirdPartyIndicator from '../../hooks/useThirdPartyIndicator';
import {
  authActions,
  GetAuthTokenOptions,
  planActions,
  rootActions,
  planHelpers
} from '@detox/actions';
import { navigate } from 'gatsby';
import Theme from '../../theme';

import findPageComponentByName from '../../helpers/findPageComponentByName';
import CisPointOfEntry from '../CisPointOfEntry';
import PlanCatalogCard from '../PlanCatalogCard/PlanCatalogCard';
import PromoBanner from '../PromoBanner';
import SelectionModal from '../SelectionModal';
import TransactionDialog from '../TransactionDialog';
import { featureFlagType } from '../../types';
import { KeyValue } from '../../types/common.types';

import {
  cisGOMOPortInEntryUrl,
  plansTermsAndConditionsUrl
} from '../../config/links';

import { flattenNodes } from '../../helpers/common';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import isMobileSharePlan from '../../helpers/is-mobile-share-plan';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import slugify from '../../helpers/slugify';
import htmlStringToReactComponent from '../../helpers/htmlStringToReactComponent';
import useAEMPage from '../../hooks/useAEMPage';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigateToSpringDPlans } from '../../helpers/navigation';
import SimOnlyPlusUpSell from './SimOnlyPlusUpSell';
import { CATALOGUE } from '../../constants';
import { TOMO_INDICATORS } from '../../constants/third-party';
import {
  getPhonesWithPrice,
  getUrlByPlan,
  sortByRankingAndTitle,
  constructProductUrl
} from '../../helpers/catalogue-helpers';
import {
  ACTION_TYPES,
  ACTION_TYPES as APP_ACTION_TYPES
} from '../../constants/actions';
import { setIppFilter } from '../../reducers/productCatalogs';
import { APP_TYPE_ANY } from '../../types/common.types';
import { getUIAMLoginUrl } from '../../config';
import { navigation } from '../../middlewares/navigation-constants';
import { clearSpecialPromo } from '../../reducers/promotions';

interface Data {
  allPlan: APP_TYPE_ANY[];
  allPhone: APP_TYPE_ANY[];
  allPlanGroup: APP_TYPE_ANY[];
  allAemPages: APP_TYPE_ANY[];
  allCis: APP_TYPE_ANY;
}

interface Props {
  data: Data;
  location: WindowLocation;
  isCisPage?: boolean;
}

export const PlanCatalog: React.FC<Props> = ({
  data,
  location,
  isCisPage = false
}) => {
  const { theme } = useTheme();
  const { hasIndicator: hasTomoIndicator } = useThirdPartyIndicator(
    TOMO_INDICATORS
  );
  const { getAuthToken, resetWorryFree } = authActions;
  const dispatch = useDispatch();
  const { auth, selectedPlan, cisUser, cisPlanRates } = useSelector(
    (state: KeyValue) => ({
      auth: state.auth,
      selectedPlan: state.plan?.selectedPlan,
      cisUser: state.user?.cis?.information,
      cisPlanRates: state.user?.cis?.information?.rates
    })
  );
  const { isMobile } = useDevice();

  useEffect(() => {
    dispatch(rootActions.resetByKeys(['product', 'user']));
    dispatch({
      type: APP_ACTION_TYPES.ORDER.RESET_ORDER
    });
    dispatch({ type: APP_ACTION_TYPES.DELIVERY.CLEAR_DELIVERY });
    dispatch(clearSpecialPromo());
  }, []);

  useEffect(() => {
    const cleanWorryFree = auth?.worryFree?.otpVerified;

    if (cleanWorryFree) {
      dispatch(getAuthToken({ cleanWorryFree }));
    } else if (auth?.worryFree?.status) {
      dispatch(resetWorryFree());
    }
  }, [auth?.worryFree?.otpVerified, auth?.worryFree?.status]);

  const [openModal, setOpenModal] = useState(false);
  const [openMSModal, setOpenMSModal] = useState(false);
  const [
    isSimOnlyPlusUpSellFeatureEnabled,
    setIsSimOnlyPlusUpSellFeatureEnabled
  ] = useState(false);
  const [showSimOnlyPlusUpSell, setShowSimOnlyPlusUpSell] = useState(false);
  const allCis = data.allCis?.nodes;

  const isCisPlanCatalogue = Boolean(cisPlanRates) && Boolean(allCis);

  let { allPlanGroup, allPhone } = data;
  const { allAemPages = [] } = data;
  allPhone = useMemo(() => flattenNodes(allPhone), [allPhone]);
  allPlanGroup = useMemo(() => flattenNodes(allPlanGroup), [allPlanGroup]);
  const allPlan = useMemo(() => {
    return planHelpers.retrievePlanList({
      aemPlans: flattenNodes(data.allPlan),
      isCisPlanCatalogue,
      cisPlanRates
    });
  }, [data.allPlan, cisPlanRates]);

  const plansPage = useAEMPage(allAemPages, 'cis-plans');

  const promoBanner =
    isCisPlanCatalogue &&
    plansPage &&
    findPageComponentByName(plansPage, 'PromoBanner');

  const [selectedPlanGroupName, setSelectedPlanGroup] = useState<string>();

  const planGroupOptions = useMemo(() => {
    let planGroupNames = [...allPlan];

    if (hasTomoIndicator) {
      planGroupNames = planGroupNames.filter(plan => !isMobileSharePlan(plan));
    }

    planGroupNames = planGroupNames.map(plan => plan.groupName);

    return allPlanGroup
      .filter(group => planGroupNames.includes(group.groupName))
      .map(group => {
        const { groupName, groupDisplayName, shortDescription } = group;

        return {
          ...group,
          text: groupDisplayName || groupName,
          icon: null,
          value: groupName,
          description: shortDescription
        };
      });
  }, [allPlanGroup, allPlan, hasTomoIndicator]);

  const selectedPlanGroup =
    planGroupOptions.find(pg => pg.groupName === selectedPlanGroupName) ||
    planGroupOptions[0];

  const renderPlans = allPlan.filter(
    plan => plan?.groupName === selectedPlanGroup?.groupName
  );

  const allSimOnlyPlusPlan = allPlan.filter(({ groupName }) =>
    groupName
      .toUpperCase()
      .includes(CATALOGUE.PLANS_GROUP_NAME.SIM_ONLY_PLUS.toUpperCase())
  );

  // Add the variants that contain installment prices to the phones array
  const phones = allPhone
    .filter(phone => !(phone.isRoi === true || phone.groupCampaignId))
    .map(phone => {
      const { variants } = phone;

      // Get all the plans that are part of the Sim Only Plus group
      const simOnlyPlusPlans = allSimOnlyPlusPlan.map(({ mecId }) => mecId);

      // Get the variants that are only part of the Sim Only Plus plans
      const simOnlyPlusVariants = variants.filter(({ planId }) =>
        simOnlyPlusPlans.includes(planId)
      );

      return {
        ...phone,
        variants: simOnlyPlusVariants
      };
    });

  const simOnlyPlanWithDefaultMonthlyTerm = {
    ...selectedPlan,
    selectedMonthlyTerm: CATALOGUE.INSTALLMENT_MONTHS[0]
  };

  const phonesWithPrice = getPhonesWithPrice(
    phones,
    simOnlyPlanWithDefaultMonthlyTerm
  );

  const sortedPhones = phonesWithPrice.sort(sortByRankingAndTitle);

  useEffect(() => {
    const { group } = getUrlParams(location.search);

    if (location.search) {
      // Find the plan group that matches the query string
      const matchedPlanGroup = planGroupOptions.find(({ groupName }) => {
        if (selectedPlan?.groupName) {
          return groupName
            .toUpperCase()
            .includes(selectedPlan.groupName.toUpperCase());
        }
        return group === slugify(groupName);
      });

      if (matchedPlanGroup) {
        setSelectedPlanGroup(matchedPlanGroup.groupName);
      } else {
        // Set the first plan group as default if no matching plan group from the url query string
        setSelectedPlanGroup(planGroupOptions[0]?.groupName);
      }
    } else {
      const defaultPlan =
        selectedPlan || allPlan.find(({ selected }) => selected) || allPlan[0];
      setSelectedPlanGroup(defaultPlan.groupName);
    }
  }, [allPlan, location.search, planGroupOptions, selectedPlan]);

  useEffect(() => {
    const { group } = getUrlParams(location.search);
    const cisCondition = !isCisPage || (isCisPage && cisPlanRates?.length);
    if (
      selectedPlanGroupName &&
      slugify(selectedPlanGroupName) !== group &&
      cisCondition
    ) {
      updateQueryFilters(location, { group: slugify(selectedPlanGroupName) });
    }
  }, [selectedPlanGroupName]);

  const isSimOnlyPlusUpsellDesignFlag = isFeatureFlagEnabled(
    featureFlagType.SIMONLYPLUS_UPSELL_DESIGN
  );

  // SIM Only Plus UpSell feature will be enabled only if both
  // feature flag is enabled in environment variables and
  // AEM ipp upsell indicator is enabled.
  // SOP upsell feature will be displayed for both RES and CIS flow.
  useEffect(() => {
    const enableSimOnlyPlusUpSellFeature =
      isSimOnlyPlusUpsellDesignFlag &&
      (isCisPlanCatalogue
        ? selectedPlanGroup.enableIppForCIS
        : selectedPlanGroup.enableIppForRES);
    setIsSimOnlyPlusUpSellFeatureEnabled(enableSimOnlyPlusUpSellFeature);

    //showing upsell section if upsell plan selected on browse back and page load
    if (
      selectedPlan &&
      isSimOnlyPlan(selectedPlan) &&
      enableSimOnlyPlusUpSellFeature
    ) {
      setShowSimOnlyPlusUpSell(true);
      window.scrollTo(0, 0);
    }
  }, [isSimOnlyPlusUpsellDesignFlag, isCisPlanCatalogue, selectedPlanGroup]);

  const handleTabChange = (planGroups, index) => {
    const { groupName } = planGroups[index];
    setSelectedPlanGroup(groupName);
    setShowSimOnlyPlusUpSell(false);
    //Resets the selected Plan
    dispatch(planActions.setSelectedPlan(undefined));
  };

  const hideSimOnlyPlusUpSellScreen = () => {
    setShowSimOnlyPlusUpSell(false);
  };

  const navigateProductDetailsPage = phone => {
    const { variants, slug } = phone;

    const url = constructProductUrl(
      variants[0]?.color,
      variants[0]?.size,
      selectedPlan.planName
    );
    dispatch({
      type: ACTION_TYPES.PLAN.EXCLUSIVE_VALIDATION_HIDE
    });
    return navigate(`/phones/${slug}/${url}`, {
      state: {
        isChangePlan: true
      }
    });
  };

  /**
   * Navigate to phone catalog page and filter for IPP devices
   */
  const showEligibleDevicesForSimOnlyPlus = () => {
    const planQueryParam = getUrlByPlan({
      ...selectedPlan,
      selectedMonthlyTerm: CATALOGUE.INSTALLMENT_MONTHS[0]
    });

    const paramItems = [
      planQueryParam,
      `${CATALOGUE.QUERYSTRING_TYPE.IPP}=${CATALOGUE.QUERY_PLANS.IPP_TEXT}`
    ];
    return navigate(`/${paramItems.join('&')}`);
  };

  const proceedSimOnlyPlusWithoutDeviceFlow = () => {
    dispatch({
      type: ACTION_TYPES.USER.SET_USER_SELECTION,
      payload: { plan: selectedPlan }
    });
    setOpenModal(true);
  };

  const handlePlanClick = plan => {
    dispatch(planActions.setSelectedPlan(plan));

    // Avoid setting userSelection for SIM Only Plus Plans when going through Upsell screen,
    // Because it should only be set later once user confirms inside UpSell screen
    if (!isSimOnlyPlan(plan) || !isSimOnlyPlusUpSellFeatureEnabled) {
      dispatch(setIppFilter(false));
      dispatch({
        type: ACTION_TYPES.USER.SET_USER_SELECTION,
        payload: { plan }
      });
    }

    if (isSimOnlyPlan(plan)) {
      isSimOnlyPlusUpSellFeatureEnabled
        ? setShowSimOnlyPlusUpSell(true)
        : setOpenModal(true);
    } else if (isMobileSharePlan(plan)) {
      setOpenMSModal(true);
    } else {
      if (isCisPlanCatalogue) {
        const { sessionToken } = getUrlParams(location.search);
        const newPath = setUrlParams('/cis-phones', {
          sessionToken,
          plan: slugify(plan.planName),
          ...(plan.ipp ? { ipp: slugify(plan.ipp) } : {})
        });
        window.location.assign(newPath);
        return;
      }
      return navigate('/');
    }
  };

  const handleMobileShareClick = flow => {
    dispatch(
      planActions.setSelectedPlan({
        ...selectedPlan,
        mobileShareOrderType: flow
      })
    );
    setOpenMSModal(false);
    return window.open(getUIAMLoginUrl(navigation.CHOOSE_NUMBER_PAGE), '_self');
  };

  const handleGomoPortInClick = () => {
    if (!isCisPlanCatalogue) {
      navigateToSpringDPlans({ groupName: selectedPlanGroup.groupName });
    } else {
      window.location.assign(cisGOMOPortInEntryUrl);
    }
  };

  const renderHeader = () => {
    const cisCompanyName = cisUser?.parentCompanyName;

    if (!isCisPlanCatalogue) {
      return (
        <Text type="pageTitle" tag="h1">
          {t('PLANS_FOR_EVERYONE')}
        </Text>
      );
    }

    return (
      <Row>
        <Column sm={12} lg={8}>
          <Text type="pageTitle">{`Postpaid Plans for ${cisCompanyName}`}</Text>
          <Spacing top={2}>
            <Text type="body">{t('CORPORATE_INDIVIDUAL_SCHEME_SUBTITLE')}</Text>
          </Spacing>
        </Column>
      </Row>
    );
  };

  const findSelectedTabIndex = (allPlanGroups, selectedPlanGroup) => {
    const index = allPlanGroups.findIndex(
      item => item.groupName === selectedPlanGroup
    );
    return index !== -1 ? index : null;
  };

  const renderPlanGroupTabs = () => {
    const addTitleKey = dataArray => {
      return dataArray.map(item => ({ ...item, title: item.text }));
    };
    const planGroups = addTitleKey(planGroupOptions);

    return (
      <Tabs
        tabs={planGroups}
        selected={
          selectedPlanGroupName &&
          findSelectedTabIndex(planGroups, selectedPlanGroupName)
        }
        onTabItemClick={(e, index) => handleTabChange(planGroups, index)}
      />
    );
  };

  const handleDataProtectionLinkClick = () => {
    window.open(plansTermsAndConditionsUrl, '_blank', 'noopener,noreferrer');
  };

  const renderPlanGroupFooterLiner = () => {
    const footerContent = selectedPlanGroup?.footerLiner
      ? htmlStringToReactComponent(selectedPlanGroup?.footerLiner)
      : t('PLANS_FOOTER_TERMS_AND_CONDITIONS', {
          tnc: (
            <TextLink
              type="smallBody"
              data-testid="terms-link"
              onClick={handleDataProtectionLinkClick}
            >
              Terms and Condition
            </TextLink>
          ) as APP_TYPE_ANY
        });

    return <Text color={theme.cl_ter_l1}>{footerContent}</Text>;
  };

  const renderPromoBanner = () => {
    return (
      promoBanner?.enablePromoBanner && (
        <PromoBanner
          bannerTheme={promoBanner.theme}
          desktopImg={
            <GatsbyImage
              image={promoBanner?.desktopImg?.childImageSharp?.gatsbyImageData}
              alt={promoBanner.desktopImgAlt}
            />
          }
          mobileImg={
            <GatsbyImage
              image={promoBanner?.mobileImg?.childImageSharp?.gatsbyImageData}
              alt={promoBanner.mobileImgAlt}
            />
          }
          title={promoBanner.title}
          description={promoBanner.description}
          enableCta={promoBanner.enableCTA}
          ctaText={promoBanner.ctaText}
          modalTitle={promoBanner.sectionTitle}
          modalDescription={promoBanner.sectionDescription}
          desktopFileReference={promoBanner.desktopFileReference}
          mobileFileReference={promoBanner.mobileFileReference}
        />
      )
    );
  };

  const renderCisPointOfEntry = () => {
    return (
      <Spacing top={isMobile ? 2 : -4}>
        <CisPointOfEntry />
      </Spacing>
    );
  };

  return (
    <>
      {!isCisPlanCatalogue ? renderCisPointOfEntry() : null}
      {renderPromoBanner()}
      <Grid>
        <Row>
          <Column xs={12} sm={12} lg={12} noGutter>
            <StyledRowMargin>
              <Spacing top={isMobile ? 1 : 4}>{renderHeader()}</Spacing>
            </StyledRowMargin>
            {planGroupOptions?.length > 1 && (
              <Spacing bottom={isMobile ? 0 : 1}>
                <Column md={12} id="plan-tabs">
                  {renderPlanGroupTabs()}
                </Column>
              </Spacing>
            )}
            {!showSimOnlyPlusUpSell && (
              <Grid>
                <StyledRow>
                  {renderPlans.map((plan, index) => {
                    const {
                      planName,
                      planDescSubLiner,
                      monthlyCharges,
                      $discountedPrice,
                      discountedPrice,
                      discountedLiner,
                      data,
                      talktime,
                      sms,
                      ribbon,
                      tags
                    } = plan;

                    return (
                      <Column xs={12} sm={12} md={4} lg={4} key={planName}>
                        <PlanCatalogCard
                          name={planName}
                          ribbon={ribbon}
                          price={monthlyCharges}
                          discountPrice={
                            isCisPlanCatalogue
                              ? $discountedPrice
                              : discountedPrice
                          }
                          discountedLiner={discountedLiner}
                          data={data}
                          talktime={talktime}
                          sms={sms}
                          tags={tags}
                          planDescSubLiner={planDescSubLiner}
                          onPlanClick={() => handlePlanClick(plan)}
                        />
                      </Column>
                    );
                  })}
                </StyledRow>
                <Spacing top={isMobile ? 2 : 0} bottom={isMobile ? 2 : 6}>
                  {renderPlanGroupFooterLiner()}
                </Spacing>
              </Grid>
            )}
            {showSimOnlyPlusUpSell && (
              <Grid>
                <StyledRow>
                  <StyledColumn sm={12} md={12}>
                    <SimOnlyPlusUpSell
                      phones={sortedPhones}
                      hideSimOnlyPlusUpSellScreen={hideSimOnlyPlusUpSellScreen}
                      proceedSimOnlyPlusWithoutDeviceFlow={
                        proceedSimOnlyPlusWithoutDeviceFlow
                      }
                      showEligibleDevicesForSimOnlyPlus={() => {
                        dispatch(setIppFilter(true));
                        dispatch({
                          type: ACTION_TYPES.USER.SET_USER_SELECTION,
                          payload: { plan: selectedPlan }
                        }),
                          showEligibleDevicesForSimOnlyPlus();
                      }}
                      navigateProductDetailsPage={navigateProductDetailsPage}
                      selectedPlanGroup={selectedPlanGroup}
                      selectedPlan={selectedPlan}
                    />
                  </StyledColumn>
                </StyledRow>
              </Grid>
            )}

            <TransactionDialog
              open={openModal}
              onClickGomoPortIn={handleGomoPortInClick}
              isGomoToSingtelEnabled={isSimOnlyPlan(selectedPlan)}
              onClose={() => setOpenModal(false)}
              data-testid="transaction-selection"
            />
            <SelectionModal
              open={openMSModal}
              onClose={() => setOpenMSModal(false)}
              data={{
                lhs: {
                  title: t('HAVE_MOBILESHARE_LINE'),
                  buttons: [
                    {
                      buttonText: t('BUTTON_RECONTRACT'),
                      onButtonClick: () =>
                        handleMobileShareClick('MS_RECONTRACT')
                    }
                  ]
                },
                rhs: {
                  title: t('WANT_MOBILESHARE_LINE'),
                  buttons: [
                    {
                      buttonText: t('CHOOSE_MAINLINE'),
                      onButtonClick: () => handleMobileShareClick('MS_NEW')
                    }
                  ]
                }
              }}
            />
          </Column>
        </Row>
      </Grid>
    </>
  );
};

const updateQueryFilters = (location, { group }) => {
  if (!location) return;
  const queryParams = getUrlParams(location.search);
  const newUrl = setUrlParams(location.pathname, {
    ...queryParams,
    group
  });
  return navigate(newUrl, { replace: true });
};

export default PlanCatalog;

const StyledRow = withTheme(styled(props => <Row {...props} />)`
  ${({ coreTheme }) => css`
    display: flex;
    margin: 0 -8px;
    @media (min-width: ${coreTheme.brk_md}) {
      margin-bottom: ${Theme.spacing(2)};
    }
  `}
`);

const StyledRowMargin = withTheme(styled(props => <Row {...props} />)`
  ${({ coreTheme }) => css`
    display: flex;
    margin: 0 12px;
    @media (min-width: ${coreTheme.brk_md}) {
      margin-bottom: ${Theme.spacing(2)};
    }
  `}
`);

const StyledColumn = styled(Column)`
  display: table-cell;
  margin-bottom: ${Theme.spacing(3)};
`;
