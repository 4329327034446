import React, { useEffect, useMemo, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Column, Grid, Row, Spacing, Tabs, Text } from '@dls/web';
import { noop } from '@lux/helpers';
import {
  authActions,
  ngnisActions,
  orderActions,
  planActions,
  rootActions,
  userActions
} from '@detox/actions';
import { simCardType } from '../config/simcard-types';
import LoadingOverlayComponent from '../components/LoadingOverlay';
import NumberSelectNew from '../components/NumberSelectNew';
import NumberPortIn from '../components/NumberPortIn';
import SEO from '../components/SEO';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import NumberSelectionAuth from '../components/NumberSelectionPageAuth';
import CHECKOUT_CONSTANTS from '../constants/checkout';
import CONSTANTS from '../constants/common';

import { TOMO_INDICATORS } from '../constants/third-party';
import useThirdPartyIndicator from '../hooks/useThirdPartyIndicator';
import { useErrorHandler } from '../hooks/useErrorHandler';
import { ACTION_TYPES } from '../constants/actions';
import {
  dataLayerPush,
  flattenNodes,
  getAemConfig,
  getErrorMessage,
  getExcludePreTickAddons,
  getSpecialPromo
} from '../helpers/common';
import {
  CHOOSE_NUMBER_TAB,
  CHOOSE_SIM_TYPE
} from '../constants/choose-sim-type';
import { FlowType } from '../types/dataLayer.type';
import {
  getChangeFlowDataLayer,
  getChooseAddonsDataLayer
} from '../helpers/dataLayerHelpers';
import { ORDER } from '../constants';
import { getUrlParams } from '@wec-core/helpers';
import { getUIAMEnabledWithToken } from '../selectors';
import { FEATURE_FLAG_SPECIAL_PROMO } from '../types/featureFlag.types';
import { setSpecialPromo } from '../reducers/promotions';
import { AEM_CONFIG_KEYS } from '../config/common';

const isGomoFlow = isFeatureFlagEnabled('GOMO_FLOW');
const is5GSAEnabled = isFeatureFlagEnabled('FEATURE_FLAG_ENABLE_5GSA');

const tabItems = [
  { title: 'Get new number', value: '#new-number' },
  { title: 'Keep number from other telco', value: '#port-in' }
];

export const NumberSelectionPage = ({
  createMobileShareNewNumberOrder,
  createNewNumberOrder,
  assignNumberToOrder,
  createPortinOrder,
  setProductOrderError,
  resetSearchNumbers,
  location,
  ngnis,
  ngnisSearchNumbersError,
  order,
  searchNumbers,
  selectedProduct,
  selectedPlan,
  selectedService,
  setPortInNumber,
  user,
  data,
  worryFreeStatus,
  resetByKeys,
  getAuthToken,
  getUserInformation,
  auth,
  resetWorryFree,
  setCheckoutNewNumberFlow = () => {},
  accessories,
  setSelectedPlan,
  apigeeTokenEnabled,
  setSpecialPromo
}) => {
  const {
    hasIndicator,
    indicator: thirdPartyIndicator
  } = useThirdPartyIndicator(TOMO_INDICATORS);

  let { allAddonPreTick, allChooseSimOptions, allPlan, allVariableConfig } =
    data || {};
  allAddonPreTick = useMemo(() => (data ? flattenNodes(allAddonPreTick) : []), [
    allAddonPreTick,
    data
  ]);
  const plans = useMemo(() => flattenNodes(allPlan), [allPlan]);
  const isMobileShareNew = selectedPlan?.mobileShareOrderType === 'MS_NEW';
  const aemVariableConfigs = flattenNodes(allVariableConfig) || [];
  const specialPromoAemData = getAemConfig(
    aemVariableConfigs,
    AEM_CONFIG_KEYS.SPECIAL_PROMO_SALE
  );
  const defaultSelectedTab = location?.hash || '#new-number';
  const isSpecialPromoEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_SPECIAL_PROMO
  );
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const [errorCount, setErrorCount] = useState(0);
  const [errorStatus, setErrorStatus] = useState('');
  const orderError = order?.error;
  const { selectedAccessories, promotions, thirdParty } = useSelector(
    state => ({
      selectedAccessories: state.accessory?.selectedAccessories,
      promotions: state.promotions,
      thirdParty: state.thirdParty
    })
  );

  const { specialPromo } = promotions;
  let pageTitle;
  const editNewNumberMode = location?.state?.editNewNumberMode;

  const dispatch = useDispatch();

  let { allErrorMessage } = data || {};
  const isNewNumberTab = selectedTab === '#new-number';
  const showNewNumberLoading = isNewNumberTab && ngnis.searchNumbers.loading;
  const showLoadingOverlay = order.loading;
  allErrorMessage = useMemo(
    () => (data ? flattenNodes(data.allErrorMessage) : []),
    [allErrorMessage, data]
  );

  const genericErrorMessage = getErrorMessage(
    thirdParty.errorCode,
    allErrorMessage
  );

  const getSimTypeValue = selectedSIMTypeValue => {
    const simType5GValue =
      selectedSIMTypeValue === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
        ? simCardType.simTypeESim
        : simCardType.simType5G;
    const simTypeValue = is5GSAEnabled ? simType5GValue : simCardType.simType4G;

    return simTypeValue;
  };

  const resetErrorMsg = () => {
    dispatch({
      type: ACTION_TYPES.THIRD_PARTY.UPDATE_INDICATOR_SUCCESS
    });
  };

  /** Handle error when thirdparty indicator is true */
  const { ctaText, ctaURL, title, description } = genericErrorMessage || {};
  const { renderError } = useErrorHandler({
    states: [
      {
        ...thirdParty,
        hasError: hasIndicator && thirdParty.hasError,
        errorMessage: description,
        errorTitle: title,
        btnText: ctaText,
        redirectNeeded: true,
        redirectUrl: ctaURL
      }
    ],
    onCloseErrorPopUp: resetErrorMsg
  });

  if (isMobileShareNew) {
    pageTitle = 'Choose a new MobileShare number';
  } else if (editNewNumberMode) {
    pageTitle = 'Select a new number';
  } else {
    pageTitle = 'Want a new line?';
  }

  const isUserLoggedin = Boolean(user?.information);

  // Get Auth Token component first renders
  useEffect(() => {
    if (apigeeTokenEnabled) {
      const cleanWorryFree = auth.worryFree.otpVerified;

      if (!auth.sessionToken || cleanWorryFree) {
        // We get token only when there is no session token,
        // or when user has logged in via WF before and needs cleaning
        getAuthToken({ cleanWorryFree });
      } else if (auth.sessionToken && auth.worryFree.status) {
        // Reset worry free error statuses if any
        resetWorryFree();
      }
    }
  }, [
    auth.worryFree.otpVerified,
    auth.worryFree.status,
    getAuthToken,
    resetWorryFree,
    auth.sessionToken,
    apigeeTokenEnabled
  ]);

  useEffect(() => {
    //Check for user information
    const getUser = async () => {
      if (!isUserLoggedin) {
        await getUserInformation();
      }
    };
    if (apigeeTokenEnabled) {
      getUser();
    }
  }, [apigeeTokenEnabled]);

  // Redirect back to landing page if we do not have a selected plan
  useEffect(() => {
    const stateFromStore = selectedPlan;
    const urlParams = getUrlParams(window.location.search);
    const { status: isSpecialPromo, specialPromo } = getSpecialPromo({
      specialPromoAemData,
      urlParams
    });
    if (isSpecialPromoEnabled && isSpecialPromo) {
      const planRelId = urlParams[CONSTANTS.URL_PARAMS.PLAN_ID];
      const selectedPlan = plans.find(plan => plan.planRelId === planRelId);
      if (selectedPlan) {
        setSpecialPromo(specialPromo);
        setSelectedPlan(selectedPlan);
      } else {
        navigate('/', { replace: true });
      }
    } else if (!stateFromStore) {
      navigate('/', { replace: true });
    }
  }, [selectedPlan]);

  // On first render, get available numbers
  useEffect(() => {
    if (apigeeTokenEnabled) {
      const fetchNumbers = async () => {
        if (worryFreeStatus) {
          await getAuthToken(true);
          resetByKeys(['user', 'auth']);
        }
        searchNumbers();
      };

      if (isUserLoggedin) {
        fetchNumbers();
      }
    }

    // Reset the numbers returned from NGNIS when component unmounts
    return () => {
      resetSearchNumbers();
    };
  }, [
    resetSearchNumbers,
    searchNumbers,
    getAuthToken,
    resetByKeys,
    worryFreeStatus,
    isUserLoggedin,
    apigeeTokenEnabled
  ]);

  const handleNewNumberClick = (number, selectedSIMType) => {
    setCheckoutNewNumberFlow();
    pushDataLayer(selectedSIMType, ORDER.TYPE.NEW);
    if (selectedPlan && isMobileShareNew) {
      const selectedMainLine = user.mainLines[selectedService.serviceId];
      createMobileShareNewNumberOrder({
        service: selectedService,
        mainLineGroupId: selectedMainLine?.groupId,
        planId: selectedPlan.planId,
        planRelId: selectedPlan.planRelId,
        numbers: ngnis.searchNumbers.numbers,
        resourceNumber: number,
        signature: ngnis.searchNumbers.signature,
        preTickAddons: allAddonPreTick,
        selectedProduct: selectedProduct?.groupId,
        simCardType: getSimTypeValue(selectedSIMType),
        contact: user?.information?.clientContext?.contact
      });
    } else {
      createNewNumberOrder({
        ...(hasIndicator && { thirdPartyIndicator }),
        ...(specialPromo &&
          isSpecialPromoEnabled && {
            hasSpecialPromo: specialPromo?.promoCode
          }),
        planId: selectedPlan.planId,
        planRelId: selectedPlan.planRelId,
        simpleProductSpecId: selectedProduct?.productId,
        numbers: ngnis.searchNumbers.numbers,
        resourceNumber: number,
        signature: ngnis.searchNumbers.signature,
        cisDetails: user.cis.information,
        preTickAddons: getExcludePreTickAddons(
          isSpecialPromoEnabled,
          allAddonPreTick,
          specialPromo
        ),
        monthlyTerm: selectedProduct?.monthlyTerm,
        selectedProduct: selectedProduct?.groupId,
        simCardType: getSimTypeValue(selectedSIMType),
        contact: user?.information?.clientContext?.contact,
        planTags: selectedPlan.tags,
        accessories: selectedAccessories
      });
    }
  };

  const handlePortInClick = (number, selectedSIMType) => {
    pushDataLayer(selectedSIMType, ORDER.TYPE.PORTIN);
    createPortinOrder({
      ...(specialPromo &&
        isSpecialPromoEnabled && {
          hasSpecialPromo: specialPromo?.promoCode
        }),
      planId: selectedPlan.planId,
      planRelId: selectedPlan.planRelId,
      simpleProductSpecId: selectedProduct?.productId,
      portinNumber: number,
      cisDetails: user.cis.information,
      preTickAddons: getExcludePreTickAddons(
        isSpecialPromoEnabled,
        allAddonPreTick,
        specialPromo
      ),
      monthlyTerm: selectedProduct?.monthlyTerm,
      selectedProduct: selectedProduct?.groupId,
      simCardType: getSimTypeValue(selectedSIMType),
      contact: user?.information?.clientContext?.contact,
      planTags: selectedPlan.tags,
      accessories: selectedAccessories
    });

    setPortInNumber(number);
  };

  useEffect(() => {
    if (orderError) {
      setErrorStatus(orderError.status);
    }

    if (orderError?.type === 'newNumber' && errorCount < 3) {
      setErrorCount(count => count + 1);
    } else if (
      orderError &&
      orderError.type === 'newNumber' &&
      errorCount >= 3
    ) {
      setErrorStatus('NGNIS_NUMBER_SELECTED_EXCEED_LIMIT_ERROR');
    }
  }, [errorCount, orderError]);

  const ngnisErrorStatus = orderError?.type === 'newNumber' && errorStatus;

  // If GOMO flow is disabled and the port in flow is a singtel telco service
  // then we show it as an error prompt to the user
  const blockSingtelPortIn =
    !isGomoFlow &&
    (order.productOrder?.telcoType === 'SINGTELPOSTPAID' ||
      order.productOrder?.telcoType === 'SINGTELPREPAID');

  // we also show the generic error message if port in failed
  const portInErrorStatus = blockSingtelPortIn
    ? 'PORT_IN_NUMBER_NOT_ELIGIBLE'
    : orderError?.type === 'portIn' && errorStatus;

  const handleOnRefresh = () => {
    // Reset the error count to 0 on refresh
    setErrorCount(0);
    // Search for a new set of numbers from NGNIS on refresh
    searchNumbers();
  };

  const pushEcommerceDataLayer = flow => {
    dataLayerPush(
      getChangeFlowDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories,
        flow,
        location: window.location
      }),
      true
    );
  };

  /**
   * Set the selected tab by manipulating the url hash
   *
   * @param {string} tab
   */
  const handleTabChange = tab => {
    if (tab === CHOOSE_NUMBER_TAB.NEW) {
      pushEcommerceDataLayer(FlowType.NEW);
    } else {
      pushEcommerceDataLayer(FlowType.PORT_IN);
    }
    setProductOrderError(false);

    setSelectedTab(tab);

    return navigate(`/number-selection${tab}`, {
      replace: true
    });
  };

  const pushDataLayer = (selectedSimType, flowType) => {
    dataLayerPush(
      getChooseAddonsDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: selectedAccessories,
        location: window.location,
        order: { sim: { selectedSimType }, type: flowType }
      }),
      true
    );
  };

  const handleNumberClick = async (number, selectedSIMType) => {
    if (editNewNumberMode) {
      await assignNumberToOrder({
        numbers: ngnis.searchNumbers.numbers,
        resourceNumber: number,
        signature: ngnis.searchNumbers.signature,
        order: order.productOrder,
        relaxCompatibilityRules: false,
        returnFullResponse: true
      });

      return navigate(location.state.editNewNumberRedirectUrl);
    } else if (selectedTab === '#new-number') {
      handleNewNumberClick(number, selectedSIMType);
    } else if (selectedTab === '#port-in') {
      handlePortInClick(number, selectedSIMType);
    }
  };

  // Render the selected components based on the selected tab
  const renderTabContent = () => {
    const renderNewNumber = (
      <NumberSelectNew
        isLoading={showNewNumberLoading}
        errorCount={errorCount}
        errorStatus={ngnisErrorStatus}
        isNgnisError={ngnisSearchNumbersError}
        numbers={ngnis.searchNumbers.numbers}
        onClick={handleNumberClick}
        onModalClose={() => setProductOrderError(false)}
        onRefresh={() => handleOnRefresh()}
        chooseSIMOptions={flattenNodes(allChooseSimOptions)}
        selectedProduct={selectedProduct}
      />
    );

    const renderPortIn = (
      <NumberPortIn
        errorStatus={
          selectedTab === '#port-in' &&
          ((portInErrorStatus && 'PORT_IN_NUMBER_NOT_ELIGIBLE') ||
            (orderError.errorMsg && 'PORT_IN_NUMBER_GENERIC_ERROR'))
        }
        onClick={handleNumberClick}
        onModalClose={() => {
          setProductOrderError(false);
          dispatch({ type: ACTION_TYPES.ORDER.RESET_CHOOSE_NUMBER_ERROR });
        }}
        chooseSIMOptions={flattenNodes(allChooseSimOptions)}
        selectedProduct={selectedProduct}
      />
    );

    if (selectedTab === '#port-in' && !isMobileShareNew) {
      return renderPortIn;
    } else if (selectedTab === '#new-number') {
      return renderNewNumber;
    } else {
      return renderNewNumber;
    }
  };

  return (
    <div className="fs-unmask">
      <SEO title="New/Port-in" />
      {showLoadingOverlay && (
        <LoadingOverlayComponent data-testid="loading-overlay" />
      )}
      <NumberSelectionAuth>
        <Grid>
          <Row>
            <Column xs={12} md={8}>
              <Row>
                <Column noGutter>
                  <Spacing bottom={2}>
                    <Text type="pageTitle">{pageTitle}</Text>
                  </Spacing>
                </Column>
              </Row>
              <Row>
                <Column noGutter>
                  {!isMobileShareNew && !editNewNumberMode && (
                    <Spacing bottom={2}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        tabs={tabItems}
                        selected={tabItems.findIndex(
                          item => item.value === selectedTab
                        )}
                        onTabItemClick={(_e, index) =>
                          handleTabChange(tabItems[index].value)
                        }
                      />
                    </Spacing>
                  )}
                  {renderTabContent()}
                </Column>
              </Row>
            </Column>
          </Row>
        </Grid>
        {renderError()}
      </NumberSelectionAuth>
    </div>
  );
};

NumberSelectionPage.defaultProps = {
  resetOrder: noop,
  resetSearchNumbers: noop,
  searchNumbers: noop,
  setPortInNumber: noop
};

NumberSelectionPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  createProductOrder: PropTypes.func,
  ngnisSearchNumbersError: PropTypes.bool,
  resetOrder: PropTypes.func,
  resetSearchNumbers: PropTypes.func,
  searchNumbers: PropTypes.func,
  selectedProduct: PropTypes.object,
  selectedPlan: PropTypes.object,
  selectedService: PropTypes.object,
  setPortInNumber: PropTypes.func,
  ngnis: PropTypes.object,
  order: PropTypes.object,
  user: PropTypes.object
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ngnisSearchNumbersError: state.ngnis.searchNumbers.error,
    selectedProduct: state.product.selectedProduct,
    accessories: state.accessory?.selectedAccessories,
    selectedPlan: state.plan.selectedPlan,
    selectedService: state.user.selectedService,
    ngnis: state.ngnis,
    order: state.order,
    user: state.user,
    auth: state.auth,
    worryFreeStatus: state.auth.worryFree.status,
    apigeeTokenEnabled: getUIAMEnabledWithToken(state)
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { searchNumbers, resetSearchNumbers } = ngnisActions;
  const {
    createMobileShareNewNumberOrder,
    createNewNumberOrder,
    createPortinOrder,
    setProductOrderError,
    assignNumberToOrder,
    setProductOrderLoading
  } = orderActions;
  const { setSelectedPlan } = planActions;
  const { getAuthToken, resetWorryFree } = authActions;
  const { getUserInformation } = userActions;
  const { resetByKeys } = rootActions;
  return {
    setSpecialPromo: specialPromo => {
      dispatch(setSpecialPromo(specialPromo));
    },
    setSelectedPlan: plan => dispatch(setSelectedPlan(plan)),
    getUserInformation: () => dispatch(getUserInformation()),
    createMobileShareNewNumberOrder: options =>
      dispatch(createMobileShareNewNumberOrder(options)),
    createNewNumberOrder: options => dispatch(createNewNumberOrder(options)),
    createPortinOrder: options => dispatch(createPortinOrder(options)),
    setProductOrderError: error => dispatch(setProductOrderError(error)),
    resetSearchNumbers: () => dispatch(resetSearchNumbers()),
    searchNumbers: options => dispatch(searchNumbers(options)),
    assignNumberToOrder: options => dispatch(assignNumberToOrder(options)),
    getAuthToken: options => dispatch(getAuthToken(options)),
    resetWorryFree: () => dispatch(resetWorryFree()),
    setProductOrderLoading: options =>
      dispatch(setProductOrderLoading(options)),
    resetByKeys: keys => dispatch(resetByKeys(keys)),
    setCheckoutNewNumberFlow: () => {
      dispatch({
        type: CHECKOUT_CONSTANTS.SET_CHECKOUT_FLOW,
        payload: CHECKOUT_CONSTANTS.CHECKOUT_NEW_NUMBER
      });
    },
    setPortInNumber: number =>
      dispatch({
        type: 'SET_PORT_IN_NUMBER',
        payload: {
          portInNumber: number
        }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberSelectionPage);

export const query = graphql`
  {
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allAddonPreTick {
      edges {
        node {
          boIds
          id
          phoneList
          phoneSpecific
          planIds
          segment
          spsIds
          transactionBoIds {
            gaBoIds
            portinBoIds
            reconBoIds
          }
          transactionSpsIds {
            gaSpsIds
            portinSpsIds
            reconSpsIds
          }
          transactionSpecific
          type
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
    allChooseSimOptions {
      edges {
        node {
          descWithDevice
          descWithoutDevice
          notAvailable
          footerWithDevice
          footerWithoutDevice
          footerNotAvailable
          simPrice {
            newSignup
            portIn
            recontract
          }
          simTitle
          simTypeId
          transactionType {
            newSignup
            portIn
            recontract
          }
        }
      }
    }
  }
`;
