import React from 'react';
import {
  Row,
  Column,
  Modal,
  Button,
  BulletList,
  Text,
  Spacing
} from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { ArgumentObjType } from '../../types/common.types';
import { hasTradeInEnabledForStoreCollection } from '../../helpers/shoppingCartHelper';

type TProps = {
  visible: boolean;
  ctaText: string;
  fulfillmentConfig: ArgumentObjType;
  onClose(): void;
  onButtonClick(): void;
};

const header = t('COLLECT_AT_STORE_ERROR_HEADER') as string;
const messagesWithTradeIn = [
  t('COLLECT_AT_STORE_ERROR_MESSAGE_1'),
  t('COLLECT_AT_STORE_ERROR_MESSAGE_3')
];
const messagesWithOutTradeIn = [
  t('COLLECT_AT_STORE_ERROR_MESSAGE_2'),
  t('COLLECT_AT_STORE_ERROR_MESSAGE_3')
];

export const CollectAtStoreModal: React.FC<TProps> = ({
  visible,
  ctaText,
  onClose,
  onButtonClick,
  fulfillmentConfig
}) => {
  const tradeInStoreCollection = hasTradeInEnabledForStoreCollection(
    fulfillmentConfig
  );
  const messages = tradeInStoreCollection
    ? messagesWithOutTradeIn
    : messagesWithTradeIn;

  return (
    <Modal
      visible={visible}
      title={header}
      onClose={onClose}
      bgColor="white"
      wide={true}
    >
      <Modal.Content>
        <Row>
          <Column sm={12} md={12} noGutter>
            <Spacing bottom={3}>
              {messages.map(message => (
                <BulletList noSpace>{message}</BulletList>
              ))}
            </Spacing>
          </Column>
        </Row>
        {ctaText && <Button onClick={onButtonClick}>{ctaText}</Button>}
      </Modal.Content>
    </Modal>
  );
};
