import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartActions,
  fulFillmentActions,
  rrpCartActions
} from '@detox/actions';
import { KeyValue } from '../../types/common.types';
import { navigate } from 'gatsby';
import { navigation } from '../../middlewares/navigation-constants';
import { CONFIGS } from '../../config/common';
import { ProductCatalogState } from '../../reducers/productCatalogs';
import { RRPCartState } from '../../reducers/rrpCart';
import { isRRPPage } from '../../helpers/common';

const rrpIppRoutes = [
  `/${navigation.RRP_IPP_FULFILMENT}`,
  `/${navigation.RRP_IPP_ORDER_SUMMARY}`
];
const isRrpIppFlow = () =>
  !!rrpIppRoutes.find(urlSlug => window.location.pathname.includes(urlSlug));

const useSessionTimer = (): KeyValue => {
  const dispatch = useDispatch();
  const sessionWarning = useRef(null);
  const sessionExpire = useRef(null);
  const isSessionTrackingPage = () => {
    return !![
      `/${navigation.ORDER_FULFILMENT_PAGE}`,
      `/${navigation.ORDER_SUMMARY}`,
      `/${navigation.RRP_SHOPPING_CART}`,
      `/${navigation.CUSTOMER_REGISTRATION}`,
      `/${navigation.RRP_FULFILMENT}`,
      `/${navigation.RRP_ORDERSUMMARY}`,
      ...rrpIppRoutes
    ].find(urlSlug => window.location.pathname.includes(urlSlug));
  };
  const [isTimerStarted, setTimerStarted] = useState(false);
  const [sessionPopUpFlag, setSessionPopUpFlag] = useState(false);

  const { rrpCancelMasterOrderItems, rrpExtendSession } = rrpCartActions;

  const {
    user,
    productOrder,
    userInformation,
    checkout,
    fulfilmentState,
    orderSummary,
    extendSessionTimeout,
    rrpCart,
    rrpCheckout,
    rrpIppCart,
    productCatalog
  } = useSelector((state: KeyValue) => {
    return {
      user: state.user,
      productOrder: state.order?.productOrder,
      userInformation: state.user?.information,
      checkout: state.checkout,
      fulfilmentState: state.fulfillment,
      orderSummary: state.orderSummary,
      extendSessionTimeout: state.extendSessionTimeout,
      rrpCart: (state?.rrpCart || {}) as RRPCartState,
      rrpCheckout: state?.rrpCheckout || {},
      rrpIppCart: state?.cart || {},
      productCatalog: state.productCatalog as ProductCatalogState
    };
  });
  const { allocateOrReserve, delivery } = fulfilmentState;
  const { cartCleared } = delivery;
  const { createdAccountId, identityCheck } = checkout;
  const rrpFlow = rrpCheckout.rrpFlow || productCatalog?.rrpFlow;
  const rrpMasterOrderId =
    rrpCheckout.rrpMasterOrderId || rrpCart.masterOrderId;
  const { isExtended } = rrpCart;

  const userContactId =
    userInformation?.clientContext?.contact?.contactId ||
    createdAccountId?.contactId ||
    identityCheck?.contactId;

  const resetTimers = () => {
    setSessionPopUpFlag(false);
    clearTimeout(sessionWarning.current);
    clearTimeout(sessionExpire.current);
  };

  const showSessionWarning = () => {
    if (orderSummary?.submitOrderData) {
      resetTimers();
    } else {
      setSessionPopUpFlag(true);
    }
  };

  const showSessionTimeOut = async () => {
    if (rrpFlow) {
      await dispatch(
        rrpCancelMasterOrderItems({
          rrpMasterOrderId
        })
      );
    } else {
      if (orderSummary?.submitOrderData === undefined) {
        const { customerId } = user;
        const { productOrderId, productOrderItemId } = isRrpIppFlow()
          ? rrpIppCart?.order?.productOrder
          : productOrder;
        dispatch(
          cartActions.emptyCart({
            customerId,
            productOrderId,
            productOrderItemId
          })
        );
      }
    }
  };

  const runSessionWarningTimer = () => {
    sessionWarning.current = window.setTimeout(function() {
      if (isSessionTrackingPage()) {
        showSessionWarning();
      }
    }, CONFIGS.EXTEND_SESSION_TIMER);
  };

  const runSessionExpireTimer = () => {
    sessionExpire.current = window.setTimeout(function() {
      if (isSessionTrackingPage()) {
        resetTimers();
        showSessionTimeOut();
      }
    }, CONFIGS.EXPIRED_SESSION_TIMER);
  };

  const onExtendSessionWarning = () => {
    const extractAllocationIDX9 = data => {
      return (data || []).map(item => item.allocationIDX9);
    };
    const allocationId = extractAllocationIDX9(
      allocateOrReserve?.data?.allocationStatusX9
    );

    setSessionPopUpFlag(false);
    if (rrpFlow) {
      dispatch(rrpExtendSession({ rrpMasterOrderId }));
    } else {
      const options = {
        contactId: userContactId,
        allocationId: allocationId,
        sessExpirationMins: 15,
        allocationStatus: 'Extend'
      };
      dispatch(fulFillmentActions.extendEquipmentReservation(options));
    }
  };

  const onCloseSessionWarning = () => {
    setSessionPopUpFlag(false);
  };

  useEffect(() => {
    if (rrpFlow) {
      if (isSessionTrackingPage() && rrpMasterOrderId && !isTimerStarted) {
        setTimerStarted(true);
        runSessionWarningTimer();
        runSessionExpireTimer();
      }
    } else {
      const reserveAllocateResponse =
        allocateOrReserve?.data?.allocationStatusX9;
      if (
        isSessionTrackingPage() &&
        allocateOrReserve?.data &&
        reserveAllocateResponse?.length > 0 &&
        !isTimerStarted
      ) {
        // Implement sessionTimeout by using two timers - 1. sessionWarning and 2.sessionExpire.
        setTimerStarted(true);
        runSessionWarningTimer();
        runSessionExpireTimer();
      }
    }

    return () => {
      if (!isSessionTrackingPage()) {
        setTimerStarted(false);
        resetTimers();
      }
    };
  }, [allocateOrReserve, isSessionTrackingPage, isTimerStarted]);

  useEffect(() => {
    if (rrpFlow && isSessionTrackingPage()) {
      resetTimers();
      runSessionWarningTimer();
      runSessionExpireTimer();
    } else {
      const response = extendSessionTimeout?.data?.allocationStatusResponse;
      if (extendSessionTimeout && response && response.length > 0) {
        resetTimers();
        runSessionWarningTimer();
        runSessionExpireTimer();
      }
    }
  }, [extendSessionTimeout, isExtended]);

  useEffect(() => {
    if (cartCleared) {
      navigate(`/${navigation.EMPTY_SHOPPING_CART}`, {
        state: {
          shopUrl: isRRPPage(window.location)
            ? `/${navigation.RRP_PRODUCT_CATALOG}`
            : '/'
        }
      });
    }
  }, [cartCleared]);

  return {
    popUpFlag: sessionPopUpFlag,
    onCloseSessionWarning: onCloseSessionWarning,
    onExtendSessionWarning: onExtendSessionWarning
  };
};

export default useSessionTimer;
