import { useEffect, useRef } from 'react';

export let isBackPressed = false;
export const setBackPress = (backPress: boolean) => {
  isBackPressed = backPress;
};

export type BrowserOptions = {
  previousPage?: string;
};
let browserOptions: BrowserOptions = {};
export const useBrowserBack = (
  callback?: (option?: BrowserOptions) => void
) => {
  const pathName = useRef(null);
  useEffect(() => {
    pathName.current = window.location.pathname;
    if (isBackPressed) {
      if (typeof callback === 'function') {
        callback(browserOptions);
      }
      isBackPressed = false;
    }
    window.addEventListener('popstate', setBackPress);
    return () => {
      window.removeEventListener('popstate', setBackPress);
    };
  }, []);

  const setBackPress = event => {
    browserOptions.previousPage = pathName.current;
    isBackPressed = true;
  };
  return { isBackPressed, setBackPress, backForwardData: browserOptions };
};
