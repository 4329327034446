const hostBridge = process.env.GATSBY_AEM_URL;
const gatsByHostUrl = process.env.GATSBY_HOST;

module.exports = {
  cisPointOfEntryUrl: `${hostBridge}/personal/products-services/mobile/cis`,
  cisGOMOPortInEntryUrl: `${hostBridge}/personal/products-services/mobile/gomoswitch`,
  feedbackFormUrl: `${hostBridge}/personal/products-services/mobile/eform/shop-feedback`,
  plansTermsAndConditionsUrl: `${hostBridge}/mobile-terms-and-conditions`,
  shoppingCartUrl: `${hostBridge}/personal/products-services/mobile/detox-phones`,
  signUpUrl: `${hostBridge}/personal/products-services/mobile/detox-phones`,
  pp2FaqUrl: `${hostBridge}/personal/products-services/mobile/postpaid-plans/simonly/SIMonly_FAQ.pdf`,
  simOnlyTncUrl: `${hostBridge}/content/dam/singtel/eshop/Agreements/simonly-mktgpage-tncs.pdf`,
  mobileShareFaqUrl: `${hostBridge}/content/dam/singtel/personal/products-services/mobile/add-ons/mobileshare/pdf/5g-mobileshare-plus-faqs-1-jan-24.pdf`,
  storeLocator: `${hostBridge}/store-locator`,
  plansUrl: `${gatsByHostUrl}/plans`,
  mobileSwopLink: `https://www.singtel.com/personal/products-services/mobile/add-ons/swop`,
  signUpNewPlanLink: `https://www.singtel.com/personal/products-services/mobile/add-ons/swop#signup`,
  cancelExistingPlanLink: `https://www.singtel.com/personal/support/mobile-postpaid/remove-add-ons/msta`,
  tncModalGeneralTermsLink: `https://www.singtel.com/content/dam/singtel/terms-condition/mobile-general-terms-and-conditions_1-jan-2023.pdf`,
  tncModalPromotionalTermsLink: `https://www.singtel.com/content/dam/singtel/eshop/Agreements/Promotional_Terms_and_Conditions_50GB_for_24mths.pdf`,
  tncModalStandardAgreement: `https://www.singtel.com/standard-agreement`,
  dppLink: `https://www.singtel.com/data-protection`,
  creditLimitRetrievedUrl: `/credit-limit-retrieved`,
  lihookWithoutTokenURL: `https://dev.billprotect.singtel.com/insurance/subscribe/hook?klbl=HOOK-PGP-2023-1014&channel=ESHOP&data=`,
  springDPromotionsPageUrl: `${hostBridge}/personal/promotions`,
  facebookShareLink: 'https://www.facebook.com/sharer/sharer.php?u=',
  telegramShareLink: 'https://telegram.me/share/url?url=',
  whatsappShareLink: 'https://wa.me/?text=',
  lineShareLink: 'https://line.me/R/msg/text/?',
  messageShareLink: 'sms:?body=',
  bibForm:
    'https://www.singtel.com/personal/products-services/mobile/eform/iphone-BIB'
};
