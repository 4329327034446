import React, {
  ReactElement,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkoutActions,
  fulFillmentActions,
  fulfillmentDeliveryActions,
  fulFillmentDocActions
} from '@detox/actions';
import { Form } from '@wec-core/form-engine';
import AddNewAddress from './AddNewAddress';

import useTranslate from '../../../hooks/useTranslation';
import {
  documentSizeValidation,
  documentTypeValidation,
  fileUploaded,
  FORM_MAPPING_ACTIONS,
  getFormDataMapping,
  getItemSkuToOrderIdMapping,
  getValidationSchema,
  uploadedFileDeleted
} from '../helper';
import {
  getBillingAddressValues,
  getBillingAddressValuesFromStore,
  isNewBillingAddress
} from './helpers';
import { getFormData } from './formData';
import {
  existingNewInitialState,
  getExistingNewFlowNonPerIniValues
} from './existingNewFlowState';
import { checkoutFlowReducer as existingNewFlowState } from '../checkoutFlowState';
import {
  BillingPrefType,
  DeliveryState,
  FormConfig,
  SelectedBillingAddress,
  SelectionObject,
  TFile,
  TObject,
  UploadedDocument
} from '../../../types/registrationCheckout';
import CONSTANTS from '../../../constants/checkout';
import { useDocumentUpload } from '../hooks/useDocumentUpload';
import { ListSkeletonLoader, SkeletonGrid } from '../../SkeletonLoader';
import { navigation } from '../../../middlewares/navigation-constants';
import { useDevice } from '@dls/web';
import isFeatureFlagEnabled from '../../../helpers/feature-flags';
import { FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API } from '../../../types/featureFlag.types';

interface TProps {
  enableReinitialize: boolean;
  stackSpacing: number;
  isCisFlow: boolean;
  idCardLinkClicked?: (hasNricFin?: boolean) => void;
  kycLinkClicked?: () => void;
  onValuesChange?: (values: TObject) => void;
  onFormSubmit?: (values: TObject, navigateTo: string) => void;
  billingPrefData: BillingPrefType[];
  onGettingFormConfigs?: (formConfigs: FormConfig) => void;
  showStepper?: () => void;
  handleCheckoutHistoryState?: (needPushState?: boolean) => void;
  goToOrderSummary?: () => void;
  isCISMyInfoFlow?: boolean;
}

export const ExistingNewFlow: React.FC<TProps> = ({
  isCisFlow,
  idCardLinkClicked,
  kycLinkClicked,
  onValuesChange,
  onFormSubmit,
  billingPrefData,
  onGettingFormConfigs,
  showStepper,
  handleCheckoutHistoryState,
  goToOrderSummary,
  isCISMyInfoFlow,
  ...props
}): ReactElement => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const {
    uploadDocument,
    deleteUploadedDocument,
    retrieveDocumentContent
  } = fulFillmentDocActions;
  const { isEBillMethod } = fulFillmentActions;
  const hasDocumentErrors = useRef<TObject>({});
  const hasNewAddressRef = useRef<boolean>(false);
  const reinitializeForm = useRef<boolean>(true);

  const {
    verificationData,
    checkoutFormData,
    checkoutFormDocumentData,
    productOrderReferenceNumber,
    documentUpload,
    downloadedDocData,
    device,
    delivery,
    billingPreference,
    contact,
    userInformation,
    productOrder,
    cart,
    selectedPlan,
    selectedProduct
  } = useSelector((state: TObject) => ({
    verificationData: state.checkout?.verificationData,
    checkoutFormData: state.checkout?.checkoutFormData,
    checkoutFormDocumentData: state.checkout?.checkoutFormDocumentData,
    documentData: state.checkout?.documentData,
    productOrderReferenceNumber:
      state.order?.productOrder?.productOrderReferenceNumber,
    documentUpload: state.checkout?.documentUpload as UploadedDocument[],
    downloadedDocData: state.documents.documentData as UploadedDocument[],
    device: state.cart?.cartOrder?.device,
    delivery: state.fulfillment?.delivery as DeliveryState,
    billingPreference: state.fulfillment?.billingPreference
      ?.billingPreference as boolean,
    contact: state.user?.information?.clientContext?.contact,
    userInformation: state.user?.information,
    productOrder: state.order?.productOrder,
    cart: state.cart,
    selectedPlan: state.plan?.selectedPlan,
    selectedProduct: state.product?.selectedProduct
  }));

  const checkoutFormDataValues = checkoutFormData ? checkoutFormData : {};
  const billingAccountDetails = delivery.deliveryDetails?.billingAccountDetails;
  const newBillingAccountDetails =
    delivery?.deliveryDetailsFromUser?.billingAccountDetails;
  const simType = cart?.order?.newlyAddedSimDetails?.simDetails?.simType;
  const [formState, formDispatch] = useReducer(
    existingNewFlowState,
    existingNewInitialState
  );

  const hasAccessories = cart?.cartOrder?.accessories?.length > 0;

  const { fetchUploadedDocuments } = useDocumentUpload({
    dispatch,
    documentUpload,
    productOrderReferenceNumber,
    retrieveDocumentContent,
    checkoutFormData,
    formDispatch,
    checkoutFormDocumentData,
    downloadedDocData
  });

  const { getNonPersonalGroup, formData } = useMemo(
    () =>
      getFormData({
        t,
        checkoutFormDocumentData,
        device,
        selectedPlan,
        billingPrefData,
        verificationData,
        isCisFlow,
        isCISMyInfoFlow
      }),
    [device, billingPrefData, verificationData, isCisFlow]
  );

  const nonPersonalInitialValues = getExistingNewFlowNonPerIniValues(isCisFlow);

  const getFromConfigPayload = (groups, formConfigs) => {
    return {
      formValues: {
        ...nonPersonalInitialValues,
        ...checkoutFormDataValues
      },
      formInputsMapping: {
        ...groups
      },
      formConfigs
    };
  };

  const getBillingPrefAddressInfo = () => {
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
      payload: []
    });
    const hasNewAddress = !!newBillingAccountDetails;
    hasNewAddressRef.current = hasNewAddress;

    let newAddressSelection = isNewBillingAddress(checkoutFormDataValues);

    if (hasNewAddress && delivery.newAddressDefault) {
      newAddressSelection = true;
    }

    const { groupConfig, groups } = getNonPersonalGroup(
      billingAccountDetails,
      checkoutFormDataValues.billingAddress,
      hasNewAddress,
      newBillingAccountDetails,
      newAddressSelection,
      billingPreference,
      simType,
      hasAccessories
    );

    return { groupConfig, groups, newAddressSelection };
  };

  const setOverAllFormState = () => {
    const { groupConfig, groups } = getBillingPrefAddressInfo();

    if (typeof onGettingFormConfigs === 'function') {
      onGettingFormConfigs({
        hasBillingPref:
          groupConfig.showBillingPref || groupConfig.showExistingBillingAddress,
        hasUploadID:
          groupConfig.showNricFIN || groupConfig.showStaffUploadDocument,
        byPassFulfilment: groupConfig.byPassFulfilment
      });
    }

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE,
      payload: getFromConfigPayload(groups, groupConfig)
    });
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
      payload: getBillingAddressValuesFromStore(checkoutFormDataValues)
    });
  };

  const setNewAddress = () => {
    const { groups, newAddressSelection } = getBillingPrefAddressInfo();

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_FORM_INPUT_MAPPING,
      payload: { billingPref: groups?.billingPref }
    });

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
      payload: newAddressSelection
        ? getBillingAddressValues()
        : getBillingAddressValuesFromStore(checkoutFormDataValues)
    });
  };

  const reserveAndAllocate = async () => {
    await checkoutActions.allocateEquipmentForAvailableProducts({
      simOptions: productOrder?.sim,
      cart,
      dispatch,
      productOrderId: productOrder?.productOrderId,
      notAvailableSkus: [],
      itemSkuToOrderIdMapping: getItemSkuToOrderIdMapping(
        selectedProduct?.deviceCode,
        productOrder?.productOrderItemId
      )
    });
  };

  useEffect(() => {
    if (handleCheckoutHistoryState) {
      handleCheckoutHistoryState();
    }
  }, []);

  useEffect(() => {
    if (contact) {
      const { contactId, indentValue, indentType } = contact;
      const payload = {
        nric: indentValue,
        contactId,
        indentType
      };

      dispatch(
        fulfillmentDeliveryActions.getDeliveryDetails({
          contactId,
          productOrderReferenceNumber
        })
      );
      // get if any account has paperbill to show bill pref
      dispatch(isEBillMethod(payload));
      fetchUploadedDocuments();
    }
  }, [userInformation]);

  useEffect(() => {
    if (delivery?.deliveryDetails) {
      reinitializeForm.current = false;
      setOverAllFormState();
      if (isFeatureFlagEnabled(FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API)) {
        reserveAndAllocate();
      }
    }
  }, [delivery?.deliveryDetails]);

  useEffect(() => {
    if (delivery?.deliveryDetailsFromUser) {
      setNewAddress();
    }
  }, [delivery?.deliveryDetailsFromUser]);

  const formattedFormFieldsData = getFormDataMapping(
    formState.formInputsMapping
  );

  const onBillPrefAddressChange = ({
    name,
    value,
    setFieldValue
  }: SelectionObject) => {
    const newAddressSelected =
      name === SelectedBillingAddress.NewBillingAddress;
    if (hasNewAddressRef.current) {
      dispatch({
        type: CONSTANTS.UPDATE_DELIVERY_ADDRESS_SELECTION,
        payload: false
      });
    }

    const billingPref = getNonPersonalGroup(
      billingAccountDetails,
      value,
      hasNewAddressRef.current,
      newBillingAccountDetails,
      newAddressSelected,
      billingPreference,
      simType,
      hasAccessories
    ).groups?.billingPref;

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_FORM_INPUT_MAPPING,
      payload: { billingPref }
    });

    const billingAddressValue = newAddressSelected
      ? SelectedBillingAddress.BillAddressValidValue
      : value;
    const newBillingAddressValue = newAddressSelected
      ? SelectedBillingAddress.BillAddressDefaultValue
      : SelectedBillingAddress.BillAddressValidValue;

    setFieldValue('billingAddress', billingAddressValue, true);
    setFieldValue('newBillingAddress', newBillingAddressValue, true);
  };

  const uploadFile = (fieldName, fieldsGroupName, docGroup, uploadedFile) => {
    return fileUploaded({
      uploadedFile,
      fieldName,
      fieldsGroupName,
      docGroup,
      hasDocumentErrors,
      productOrderReferenceNumber,
      uploadDocument,
      dispatch,
      formDispatch,
      t
    });
  };

  const deleteFile = (fileName, fieldName, fieldsGroupName, docGroup) => {
    return uploadedFileDeleted({
      fileName,
      fieldName,
      fieldsGroupName,
      docGroup,
      productOrderReferenceNumber,
      documentUpload,
      dispatch,
      formDispatch,
      deleteUploadedDocument
    });
  };

  const _onIdCardLinkClicked = () => {
    idCardLinkClicked(formState.formConfigs.showNricFIN);
  };

  const _addAddressLinkClicked = (): void => {
    formDispatch({
      type: FORM_MAPPING_ACTIONS.TOGGLE_ADD_NEW_ADDRESS_MODAL,
      payload: true
    });
  };

  const closeAddressModal = () => {
    formDispatch({
      type: FORM_MAPPING_ACTIONS.TOGGLE_ADD_NEW_ADDRESS_MODAL,
      payload: false
    });
  };

  const callbacks = () => {
    const documentsGroup = formState.formInputsMapping['documents'];
    const staffGroup = formState.formInputsMapping['staffPass'];
    const kycGroup = formState.formInputsMapping['kyc'];
    return {
      onBillPrefAddressChange,
      idCardLinkClicked: _onIdCardLinkClicked,
      kycLinkClicked,
      addAddressLinkClicked: _addAddressLinkClicked,
      documentTypeValidation: (file: File) => documentTypeValidation(file, t),
      documentSizeValidation: (file: File) => documentSizeValidation(file, t),
      nricFrontDropped: (file: TFile) =>
        uploadFile('nricFrontUpload', 'documents', documentsGroup, file),
      nricFrontDeleted: fileName =>
        deleteFile(fileName, 'nricFrontUpload', 'documents', documentsGroup),
      nricBackDropped: (file: TFile) =>
        uploadFile('nricBackUpload', 'documents', documentsGroup, file),
      nricBackDeleted: fileName =>
        deleteFile(fileName, 'nricBackUpload', 'documents', documentsGroup),
      staffPassDropped: (file: TFile) =>
        uploadFile('staffPassUpload', 'staffPass', staffGroup, file),
      staffPassDeleted: fileName =>
        deleteFile(fileName, 'staffPassUpload', 'staffPass', staffGroup),
      kycDropped: (file: TFile) =>
        uploadFile('kycUpload', 'kyc', kycGroup, file),
      kycDeleted: fileName => deleteFile(fileName, 'kycUpload', 'kyc', kycGroup)
    };
  };

  const _onValidate = _ => {
    return hasDocumentErrors.current;
  };

  const _onFormSubmit = values => {
    const groupConfigs = formState.formConfigs;

    if (groupConfigs?.byPassFulfilment) {
      return goToOrderSummary();
    }
    onFormSubmit(values, `/${navigation.ORDER_FULFILMENT_PAGE}`);
  };

  useEffect(() => {
    if (
      !delivery.isLoading &&
      !delivery.isDeliveryLoading &&
      formattedFormFieldsData?.length
    ) {
      showStepper();
    }
  }, [delivery.isLoading, delivery.isDeliveryLoading, formattedFormFieldsData]);

  const renderContent = () => {
    if (
      delivery.isLoading ||
      delivery.isDeliveryLoading ||
      !formattedFormFieldsData?.length
    ) {
      return (
        <>
          <SkeletonGrid numberOfColumn={isMobile ? 1 : 2} numberOfRow={2} />
          <ListSkeletonLoader numberOfItem={3} />
        </>
      );
    }
    return (
      <div className="fs-mask">
        {formState.addNewAddressModalOpen && (
          <AddNewAddress
            isOpen={formState.addNewAddressModalOpen}
            closeAddressModal={closeAddressModal}
            keepAddress={hasNewAddressRef.current}
          />
        )}
        <Form
          {...props}
          enableReinitialize={reinitializeForm.current}
          valuesChangeOnDemand={formState.valuesChangeOnDemand}
          formFieldsConfig={formattedFormFieldsData}
          initialValues={formState.formValues}
          data={formData}
          callbacks={callbacks()}
          onValueChange={onValuesChange}
          onSubmit={_onFormSubmit}
          onValidate={_onValidate}
          validationSchema={getValidationSchema(t)}
        />
      </div>
    );
  };
  return renderContent();
};

export default ExistingNewFlow;
