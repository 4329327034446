import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fulFillmentActions } from '@detox/actions';
import { REQUEST_STATUS } from '../../constants';

const {
  checkFulfillmentStocks,
  getCollectNowStock,
  getMTPOSStock
} = fulFillmentActions;

export const STOCK_CHECK_TYPE = {
  ALL: 1,
  LAMP: 2,
  MTPOS: 3,
  STORE: 4,
  STORE_MULTIPLE_SKUS: 5
};

const useStockCheck = (type, storeIdPickup = []) => {
  const dispatch = useDispatch();
  const { lampStock, mtposStock } = useSelector(state => state.fulfillment);
  const deviceSku = useSelector(
    state => state.cart?.order?.mobile?.device?.sku
  );
  const { freebies, accessories = [] } = useSelector(state => ({
    freebies: state.cart?.cartOrder?.freebies,
    accessories: state.cart?.cartOrder?.accessories
  }));

  const freebiesSkuIds = freebies ? freebies.map(freebie => freebie.skuId) : [];
  const deviceSkus = [deviceSku, ...freebiesSkuIds];

  const [stockAvailable, setStockAvailable] = useState({
    status: true,
    notAvailableSkus: [],
    availableStoreIds: [],
    isFetched: false
  });

  const [loading, setLoading] = useState(false);

  const getStockStatus = (itemAvailability = []) => {
    return itemAvailability.reduce(
      (result, item) => {
        if (item.itemCode === deviceSku) {
          result.status = item.availableQty > 0;
        } else if (item.availableQty <= 0 || item.status === 'NA') {
          result.unavailableItems.push({ sku: item.itemCode });
        }
        return result;
      },
      { status: false, unavailableItems: [] }
    );
  };

  const checkStocks = async () => {
    let isAvailable = true;
    let notAvailableSkus = [];
    let availableStoreIds = [];

    switch (type) {
      case STOCK_CHECK_TYPE.ALL:
        await dispatch(checkFulfillmentStocks({ deviceSkus })).then(res => {
          const { status, unavailableItems } = getStockStatus(res?.mtpos);
          notAvailableSkus = unavailableItems;
          isAvailable = res?.collectNow?.length > 0 || status;
        });
        break;

      case STOCK_CHECK_TYPE.LAMP:
        await dispatch(getCollectNowStock({ skuList: deviceSkus })).then(
          res => {
            isAvailable = res?.length > 0;
          }
        );
        break;

      case STOCK_CHECK_TYPE.MTPOS:
        await dispatch(getMTPOSStock({ skuList: deviceSkus }))?.then(res => {
          const { status, unavailableItems } = getStockStatus(res);
          notAvailableSkus = unavailableItems;
          isAvailable = status;
        });
        break;
      case STOCK_CHECK_TYPE.STORE: {
        await dispatch(
          fulFillmentActions.getStoreAvailability({
            itemCode: deviceSku,
            storeIdPickup
          })
        )?.then(res => {
          isAvailable = res?.itemAvailability?.some(
            storeAvailability => storeAvailability.availableQty > 0
          );
          availableStoreIds = res?.itemAvailability;
        });
        break;
      }
      case STOCK_CHECK_TYPE.STORE_MULTIPLE_SKUS: {
        const skus = [
          deviceSku,
          ...accessories.map(accessory => accessory.skuId)
        ];
        const res = await dispatch(
          fulFillmentActions.getStoreAvailabilityForMultipleSkus({
            skus,
            storeIdPickup
          })
        );
        const storesAvailability = res?.reduce(
          (result, item, index) => {
            const firstItem = index === 0;
            if (firstItem || result.isAvailable) {
              const storesHaveStock = item?.itemAvailability?.filter(
                store =>
                  store.availableQty > 0 &&
                  (firstItem ||
                    result.availableStoreIds.includes(store.storeID))
              );

              result.isAvailable = storesHaveStock?.length > 0;
              result.availableStoreIds = storesHaveStock?.map(
                store => store.storeID
              );
            }
            return result;
          },
          {
            availableStoreIds: [],
            isAvailable: false
          }
        );

        isAvailable = storesAvailability?.isAvailable;
        availableStoreIds = storesAvailability?.availableStoreIds || [];
      }
      default:
        break;
    }

    setStockAvailable({
      status: isAvailable,
      notAvailableSkus,
      availableStoreIds,
      isFetched: true
    });
  };

  useEffect(() => {
    if (deviceSku) {
      checkStocks();
    }
  }, [deviceSku]);

  useEffect(() => {
    let isLoading = false;
    switch (type) {
      case STOCK_CHECK_TYPE.ALL:
        isLoading =
          mtposStock?.status === REQUEST_STATUS.PENDING ||
          lampStock?.status === REQUEST_STATUS.PENDING;
        break;

      case STOCK_CHECK_TYPE.LAMP:
        isLoading = lampStock?.status === REQUEST_STATUS.PENDING;

        break;

      case STOCK_CHECK_TYPE.MTPOS:
        isLoading = mtposStock?.status === REQUEST_STATUS.PENDING;
        break;

      case STOCK_CHECK_TYPE.STORE:
        isLoading = mtposStock?.status === REQUEST_STATUS.PENDING;
        break;

      default:
        break;
    }

    setLoading(isLoading);
  }, [type, mtposStock?.status, lampStock?.status]);

  return { loading, stockAvailable };
};

export const useRrpStockCheck = (isMounted, rrpOrder) => {
  const dispatch = useDispatch();

  //const rrpOrder = useSelector(state => state.cart?.rrpOrder);
  useEffect(async () => {
    if (rrpOrder && isMounted) {
      const itemskus = rrpOrder.ImplRRPOrderDetails.flatMap(packageItem => {
        return packageItem.orderItems.map(orderItem => {
          return orderItem.sku;
        });
      });
      await dispatch(getMTPOSStock({ skuList: itemskus }));
    }
  }, [rrpOrder, isMounted]);
};

export default useStockCheck;
