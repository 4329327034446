import {
  IAccessories,
  IVariant,
  StockAvailability
} from '@common-modules/product-catalogue';
import {
  Accessory,
  AccessoryImage,
  AccessoryVariant,
  ImagesByColor
} from '../types/accessory.types';
import { GatsbyImage } from '../types/common.types';
import { api } from '@detox/actions';
import isFeatureFlagEnabled from './feature-flags';
import { FEATURE_FLAG_MTPOS_FS_API_MIGRATION } from '../types/featureFlag.types';

const isFSMtposMigrationEnabled = isFeatureFlagEnabled(
  FEATURE_FLAG_MTPOS_FS_API_MIGRATION
);
const fetchOnlineStock = isFSMtposMigrationEnabled
  ? api.onlineApi.eShopStockAvailability
  : api.mcss.stockAvailability;

const getGatsbyImages = (images: GatsbyImage[] = []): AccessoryImage[] => {
  return images?.map(image => {
    return {
      src: image?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
    };
  });
};

const getAccessoryImagesByColor = (
  variants: AccessoryVariant[]
): ImagesByColor => {
  return variants.reduce((result: ImagesByColor, variant) => {
    const colorCode = variant.hexCode;
    const imageGroup = result[colorCode];
    const images = getGatsbyImages(variant.images);
    if (!imageGroup) {
      result[colorCode] = images;
    } else {
      images.forEach(image => {
        if (!imageGroup.some(imageInGroup => imageInGroup.src === image.src)) {
          imageGroup.push(image);
        }
      });
    }
    return result;
  }, {});
};

const mapAccessoryVariants = (
  accessory: Accessory,
  deviceTitle: string,
  deviceDescription: string,
  recommendedVariants: string[],
  deviceId: string | number,
  stockCount?: number
): {
  mappedVariants: IVariant[];
  selectedVariant?: IVariant;
  order: number;
} => {
  const accessoryVariants = accessory.variants || [];
  const imagesGroupByColor = getAccessoryImagesByColor(accessoryVariants);
  let selectedVariant, order;
  const mappedVariants = accessoryVariants.map(variant => {
    const variantIndex = recommendedVariants.findIndex(
      id => id === variant.identifier
    );
    if (variantIndex >= 0 && (order === undefined || variantIndex < order)) {
      order = variantIndex;
    }
    const returnObj = {
      id: variant.identifier,
      colorCode: variant.name,
      colorHexCode: variant.hexCode,
      colorDescription: variant.name,
      deviceId: deviceId,
      price: variant.price,
      usualPrice: variant.usualPrice,
      images: imagesGroupByColor[variant.hexCode],
      deviceTitle,
      deviceDescription,
      deviceCode: variant.sku,
      stockCount,
      memorySize: variant.size,
      tags: accessory.tags,
      brand: variant.brand
    } as IVariant;
    if (!selectedVariant && recommendedVariants.includes(variant.identifier)) {
      selectedVariant = returnObj;
    }
    return returnObj;
  });
  return { mappedVariants, selectedVariant, order };
};

const mapAccessory = ({
  accessory,
  recommendedAccessories,
  id,
  stockCount
}: {
  accessory: Accessory;
  recommendedAccessories: string[];
  id: string | number;
  stockCount?: number;
}) => {
  const { title, description } = accessory;
  const { mappedVariants, selectedVariant, order } = mapAccessoryVariants(
    accessory,
    title,
    description,
    recommendedAccessories,
    id,
    stockCount
  );

  return {
    ...accessory,
    deviceId: id,
    title,
    description,
    variants: mappedVariants,
    selectedVariant: selectedVariant || mappedVariants[0],
    ribbon: accessory.ribbon,
    order: order >= 0 ? order : 999 // accessory without order will be at the last of the list.
  };
};
const mapAccessories = async (
  recommendedAccessories: string[] = [],
  accessories: Accessory[] = []
): Promise<IAccessories[]> => {
  const filteredAccessories = accessories.filter(accessory => {
    return accessory.variants.find(variant =>
      recommendedAccessories.includes(variant.identifier)
    );
  });
  const skusToCheckStock = getAccessorySkusToCheckStock(filteredAccessories);
  let stockAvailability = [];
  if (skusToCheckStock.length) {
    stockAvailability = await getAccessoryOnlineStock(skusToCheckStock);
  }

  return filteredAccessories.reduce<IAccessories[]>(
    (result: IAccessories[], accessory, index) => {
      const isSingleSkuAccessory = accessory.variants?.length === 1;
      if (isSingleSkuAccessory) {
        const stockCount = stockAvailability?.find(
          stock => stock.itemCode === accessory.variants[0]?.sku
        )?.availableQty;
        if (stockCount > 0) {
          result.push(
            mapAccessory({
              accessory,
              recommendedAccessories,
              id: index,
              stockCount
            })
          );
        }
      } else {
        result.push(
          mapAccessory({ accessory, recommendedAccessories, id: index })
        );
      }
      return result;
    },
    []
  );
};

const getAccessorySkusToCheckStock = (accessories?: Accessory[]) => {
  if (!accessories) return [];
  return accessories?.reduce((result: string[], accessory) => {
    if (accessory.variants?.length === 1) {
      result.push(accessory.variants[0].sku);
    }
    return result;
  }, []);
};

const getAccessoryOnlineStock = async (skus): Promise<StockAvailability[]> => {
  try {
    const availabilityList = await fetchOnlineStock(skus);
    return Array.isArray(availabilityList) ? availabilityList : [];
  } catch (e) {
    return [];
  }
};

export {
  mapAccessories,
  getAccessoryImagesByColor,
  getAccessoryOnlineStock,
  getAccessorySkusToCheckStock
};
