import { useEffect, useState } from 'react';

import { api } from '@detox/actions';

import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { FEATURE_FLAG_MTPOS_FS_API_MIGRATION } from '../../types/featureFlag.types';

const isFSMtposMigrationEnabled = isFeatureFlagEnabled(
  FEATURE_FLAG_MTPOS_FS_API_MIGRATION
);
const fetchStockAvailability = isFSMtposMigrationEnabled
  ? api.onlineApi.eShopStockAvailability
  : api.mcss.stockAvailability;

const fetchOfflineStockApi = api.aem.offlineStockAvailability;

/**
 * Check if offline stock is available or not available
 *
 * @param {object} list
 * @param {string} skuId
 * @returns {number}
 */
const getOfflineStockForSku = (list, skuId) => {
  return Number(
    list
      .find(sku => sku.skuId === skuId)
      .data.some(store => Number(store.available_quantity) > 0)
  );
};

/**
 * Check if offline stock status is "Not Available". We are not going to check for anything that is available
 * Statues: "Available", "Selling Fast", "Not Available"
 *
 * @param {object} list
 * @param {string} skuId
 * @returns {number}
 */
const getOfflineStockStatusForSku = (list, skuId) => {
  const matchedSku = list.find(sku => sku.skuId === skuId);

  // Check if an existing SKU matched with the offline stock availabity response. If undefined, return 0.
  if (matchedSku) {
    return Number(
      matchedSku.data.some(store => {
        const { stock_status } = store;

        const arrStatus = ['Available', 'Selling Fast'];

        return arrStatus.includes(stock_status);
      })
    );
  } else {
    return 0;
  }
};

const fetchOfflineStock = async groupId => {
  try {
    const response = await fetchOfflineStockApi(groupId);
    return response[0].items;
  } catch (e) {
    return [];
  }
};

const useStockAvailability = (
  skuVariants,
  sessionToken,
  groupId,
  checkOfflineStock,
  apigeeTokenEnabled = true
) => {
  const [stockAvailability, setStockAvailability] = useState([]);
  const [stockCheckloading, setStockCheckloading] = useState(false);

  useEffect(() => {
    let skus;

    if (Array.isArray(skuVariants)) {
      skus = skuVariants.map(s => s.sku);
    } else if (skuVariants?.sku) {
      skus = [skuVariants.sku];
    }

    const skuIds = Array.from(new Set(skus));

    let didCancel = false;

    const getStockAvailability = async () => {
      let itemAvailability, offlineStockAvail;

      const isPhoneAndFeatureFlagged =
        isFeatureFlagEnabled('ONLINE_STOCK_FROM_ESHOP') && checkOfflineStock;
      const isFreeGiftAndFeatureFlagged =
        isFeatureFlagEnabled('ONLINE_STOCK_FROM_ESHOP') && !checkOfflineStock;
      if (isPhoneAndFeatureFlagged) {
        // We fetch eshop stock data here if feature flag is enabled
        setStockCheckloading(true);
        offlineStockAvail = await fetchOfflineStock(groupId);
        setStockCheckloading(false);

        itemAvailability = offlineStockAvail.map(sku => {
          // Inside the payload exists a store called WareHouse,
          // which tells us the online stock availability.
          const onlineStock = sku.data.find(
            data => data['store_id'] === 'WareHouse'
          );

          let status = 'NA';

          if (onlineStock && onlineStock.available_quantity > 0) {
            status = 'AL';
          }

          return {
            status: status,
            itemCode: sku.skuId,
            availableQty: Number(onlineStock.available_quantity)
          };
        });
      } else if (isFreeGiftAndFeatureFlagged) {
        // We don't fetch any stocks for free gifts if the feature flag is enabled
        // Instead, we treat each free gift stock as available so we don't
        // show 'Out of Stock'
        itemAvailability = skuIds.map(skuId => ({
          status: 'AL',
          availableQty: 1,
          itemCode: skuId
        }));
      } else {
        // If feature flag is not enabled we call the mcss online stock api directly
        try {
          setStockCheckloading(true);
          itemAvailability = await fetchStockAvailability(skuIds);
          setStockCheckloading(false);
        } catch (e) {
          setStockCheckloading(false);
          itemAvailability = [];
        }
      }

      let skuMissingfromApiResponse = skuIds.filter(
        sku => !itemAvailability?.map(item => item.itemCode).includes(sku)
      );

      // We want to add these sku that are missing from the API response to the array as no quantity
      skuMissingfromApiResponse.forEach(sku => {
        itemAvailability.push({
          status: 'NA',
          itemCode: sku,
          availableQty: 0
        });
      });

      if (!didCancel) {
        const hasSkuWithNoOnlineStock = itemAvailability.some(
          item => item.availableQty <= 0
        );

        if (hasSkuWithNoOnlineStock && checkOfflineStock) {
          // We only call the offline stock avail api only when one of the skus has no online stock
          try {
            if (!offlineStockAvail) {
              // If we haven't called the offline stock api above, we fetch now
              setStockCheckloading(true);
              offlineStockAvail = await fetchOfflineStock(groupId);
              setStockCheckloading(false);
            }
            // Here we use the offline stock only when the online stock is 0.
            // If not, we use the online stock count.
            itemAvailability = itemAvailability?.map(item => {
              let stockQty = item.availableQty;

              // We check for the offline stock status when the online stock quantity is 0
              if (stockQty <= 0) {
                stockQty = getOfflineStockStatusForSku(
                  offlineStockAvail,
                  item.itemCode
                );

                // We check for the offline stock quantity if stock status is not "Not Available"
                if (stockQty !== 0) {
                  stockQty = getOfflineStockForSku(
                    offlineStockAvail,
                    item.itemCode
                  );
                }
              }

              return {
                ...item,
                availableQty: stockQty
              };
            });
          } catch (e) {}
        }

        setStockAvailability(itemAvailability);
      }
    };

    // Must wait for a session token
    if (sessionToken && skuIds.length > 0 && apigeeTokenEnabled) {
      getStockAvailability();
    }

    return () => {
      didCancel = true;
    };
  }, [
    checkOfflineStock,
    groupId,
    sessionToken,
    skuVariants,
    apigeeTokenEnabled
  ]);

  return [stockAvailability, stockCheckloading];
};

export default useStockAvailability;
