import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Row, Column, Text, Stepper, Spacing } from '@dls/web';
import GenericError from '../ShoppingCart/GenericError';
import CONSTANTS from '../../constants/checkout';
import { ORDER } from '../../constants';
import useTranslate from '../../hooks/useTranslation';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { CP9_FLOW, ENABLE_MANUAL_FLOW, FEATURE_FLAG_SPECIAL_PROMO } from '../../types/featureFlag.types';
import InformationModal from './InformationModals';
import NewNewFlow from './NewNewFlow';
import ReconFlow from './ReconFlow';
import CPModal from './CPModals';

import { customerMyInfoActions, fulFillmentActions } from '@detox/actions';

import {
  getStepperTilesData,
  MODAL_TYPES,
  getUpdatedValues,
  navigateToOrderSummary,
  isInCompleteSingpassData,
  handleBillingAddress,
  getItemSkuToOrderIdMapping
} from './helper';
import {
  FormConfig,
  TObject,
  ErrorScenarioType,
  DeliveryState
} from '../../types/registrationCheckout';
import ExistingNewFlow from './ExistingNewFlow';
import { navigate } from 'gatsby';
import LoadingOverlayComponent from '../LoadingOverlay';
import useRootNavigation from '../../hooks/useRootNavigation';
import useUser from '../../hooks/useUser';
import { useFlowCheck } from '../../hooks/useFlowCheck/useFlowCheck';
import { navigation } from '../../middlewares/navigation-constants';
import { UserInformation } from '../../types/user.types';
import { BillType } from '../../types/registrationCheckout';
import { ACTION_TYPES } from '../../constants/actions';
import { AEM_CONFIG_KEYS, CP_ERROR_CODES } from '../../config/common';
import { getCreditLimitErrorProps } from '../../helpers/credit-limit';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { getUrlParams } from '@lux/helpers';
import MyInfoEKYCModal from '../MyInfoEKYCModal/MyInfoEKYCModal';
import RESMyInfoFlow from './RESMyInfoFlow';
import CISMyInfoFlow from './CISMyInfoFlow';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import {
  getAccountBillingInfo,
  flattenNodes,
  dataLayerPush,
  getAemConfigs
} from '../../helpers/common';
import { getFulfilmentParams } from '../Fulfilment/helpers';
import { isSimOnlyWithoutDevice } from '../../helpers/shoppingCartHelper';
import FULFILMENT_CONSTANTS from '../../constants/fulfillment';
import { setConfirmFulfilment } from '../../reducers/fulfillment/delivery';
import { getCheckoutDataLayer } from '../../helpers/dataLayerHelpers';
import {
  getChangeCISEKYCFlow,
  getIfCISMyInfoFlow,
  getIfESimOrder,
  getIfSimOnlyESIMPortInOrder
} from '../../selectors';
import {
  getPreFilledCheckoutData,
  getSimOnlyEsimDeliveryPayload
} from './myinfoHelper';
import { useBrowseBackPopup } from '../../hooks/useBrowseBackPopup';
import { KeyValue } from '../../types/common.types';

interface TProps {
  data: TObject;
}

type BillingPreference = {
  billingPreferenceDesc: string;
  billingPreferenceKey: string;
  billingPreferencePrice: string;
  billingPreferenceTitle: string;
}

const Checkout: React.FC<TProps> = (props): ReactElement => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const {
    allBillingPreference,
    allErrorMessage,
    allVariableConfig
  } = props.data;
  const billPrefFlattened = flattenNodes(allBillingPreference);
  const errorScenariosFlattened = flattenNodes(allErrorMessage);
  const variableConfigs = flattenNodes(allVariableConfig) || [];
  const [enableChangeCisMyInfo = ''] = getAemConfigs(variableConfigs, [
    AEM_CONFIG_KEYS.DETOX_CIS_MYINFO_CHANGE_SKIP_DOC_UPLOAD
  ]);

  const [errorScenarioMessage, setErrorScenarioMessage] = useState<
    ErrorScenarioType | undefined
  >();
  const [showInfoModal, setShowInfoModal] = useState({
    showModal: false,
    modalType: null
  });
  const [isMyInfoMisMatch, setIsMyInfoMisMatch] = useState(false);
  const [singpassCancel, setSingpassCancel] = useState(false);
  const [hideStepper, setHideStepper] = useState(false);
  const manualFormEnabled = isFeatureFlagEnabled(ENABLE_MANUAL_FLOW);
  const isSpecialPromoEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_SPECIAL_PROMO
  );
  const pathName = window.location.search;
  const isFromMyInfo = pathName.includes(
    ACTION_TYPES.USER_IDENTITY_CHECK.SINGPASS_SUCCESS_CODE
  );
  const isFromMyInfoWithError =
    pathName.includes(ACTION_TYPES.USER_IDENTITY_CHECK.SINGPASS_ERROR_CODE) ||
    false;

  const {
    cisUser,
    orderType,
    auth,
    cart,
    stepperTitles,
    userInformation,
    checkout,
    productOrder,
    customerMyInfoState,
    selectedProduct,
    selectedPlan,
    delivery,
    fulfilmentState,
    accessory,
    hasESimOrder,
    userCISMyInfoAvailable,
    changeCISMyInfoFlow,
    simOnlyeSIMOrder,
    specialPromo
  } = useSelector((state: KeyValue) => ({
    cisUser: state.user?.cis?.information,
    checkout: state.checkout,
    auth: state.auth,
    orderType: state.order?.productOrder?.type,
    productOrder: state.order?.productOrder,
    cart: state.cart,
    stepperTitles: state.checkout?.stepperTitles,
    userInformation: state.user?.information,
    customerMyInfoState: state?.customerMyInfo,
    selectedProduct: state.product?.selectedProduct,
    selectedPlan: state.plan?.selectedPlan,
    delivery: state.fulfillment?.delivery as DeliveryState,
    fulfilmentState: state.fulfillment,
    accessory: state.accessory,
    hasESimOrder: getIfESimOrder(state),
    userCISMyInfoAvailable: getIfCISMyInfoFlow(state),
    changeCISMyInfoFlow: getChangeCISEKYCFlow(state, enableChangeCisMyInfo),
    simOnlyeSIMOrder: getIfSimOnlyESIMPortInOrder(state),
    specialPromo: state?.promotions?.specialPromo?.promoCode
  }));
  const hasSpecialPromo = isSpecialPromoEnabled && specialPromo;
  const filteredBillPref = hasSpecialPromo
    ? billPrefFlattened.filter(
        (bill: BillingPreference) =>
          bill?.billingPreferenceKey !== BillType.PAPER_BILL
      )
    : billPrefFlattened;
  const checkoutFlow = checkout?.checkoutFlow;
  const isNewNewCheckout =
    checkoutFlow === CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER ||
    checkoutFlow === CONSTANTS.CHECKOUT_NEW_NUMBER;
  const customerMyInfoResponse = customerMyInfoState?.customerInfoResponse;
  const prevMyInfoSuccessCode = customerMyInfoState?.successCode;
  const isCompleteMyInfoData = isInCompleteSingpassData(
    customerMyInfoResponse?.ImplCustomerInfoResponse
  );

  const { isLoading: infoLoading = false } = customerMyInfoState || {};
  const { isUserLoggedIn, hasUserInfo } = useUser(isNewNewCheckout);

  const { renderError } = useErrorHandler({
    states: [customerMyInfoState]
  });

  const setStepperTitles = stepperTitles => {
    dispatch({ type: CONSTANTS.SET_STEPPER_TITLES, payload: stepperTitles });
  };

  const proceedModalAction = async () => {
    //skip login guest checkout and go directly to shopping cart.
    window.history.go(isNewNewCheckout ? -2 : -1);
  };
  const {
    pushStateForBrowseBackHandling,
    renderBrowseBackModal
  } = useBrowseBackPopup({
    pageName: navigation.CHECKOUT,
    onConfirmBrowseBack: proceedModalAction,
    preventBrowseBack: !isFromMyInfo,
    customPopstate: isFromMyInfo
      ? () => {
          window.history.go(-2);
        }
      : null
  });

  useEffect(() => {
    if (isFromMyInfo) {
      const urlParams = getUrlParams();
      const options = {
        redirectUrl: `${window.location.origin}/${navigation.CHECKOUT}`,
        data: urlParams?.code,
        status: urlParams?.state
      };

      if (urlParams?.code !== prevMyInfoSuccessCode) {
        dispatch({
          type: CONSTANTS.SET_MYINFO_SUCCESS_CODE,
          value: urlParams?.code
        });
        dispatch({
          type: ACTION_TYPES.USER_IDENTITY_CHECK.CLEAR_MY_INFO_URL
        });

        dispatch(customerMyInfoActions.getCustomerMyInfo(options));
      }
    } else if (
      pathName.includes(ACTION_TYPES.USER_IDENTITY_CHECK.SINGPASS_ERROR_CODE)
    ) {
      setSingpassCancel(true);
    }
  }, [pathName]);

  useEffect(() => {
    const isEKycFailed = !!customerMyInfoResponse?.ImplCustomerInfoResponse
      ?.idTypeMismatch;
    if (customerMyInfoResponse && isEKycFailed) {
      setIsMyInfoMisMatch(true);
    }
  }, [customerMyInfoResponse]);

  useEffect(() => {
    if (!productOrder) {
      navigate('/', { replace: true });
    }
  }, [productOrder]);

  useEffect(() => {
    if (fulfilmentState?.delivery?.submitSuccess) {
      dispatch({
        type: FULFILMENT_CONSTANTS.FULFILMENT_ACTIONS.RESET_FULFILMENT
      });
      dispatch(setConfirmFulfilment(true));
      navigateToOrderSummary();
    }
  }, [fulfilmentState?.delivery?.submitSuccess]);

  useRootNavigation(cart);
  useFlowCheck({ flowData: [cart?.cartOrder] });
  useEffect(() => {
    switch (orderType) {
      case ORDER.TYPE.RECON:
        dispatch({
          type: CONSTANTS.SET_CHECKOUT_FLOW,
          payload: CONSTANTS.CHECKOUT_RECON
        });
        break;
      case ORDER.TYPE.MS_NEW:
      case ORDER.TYPE.PORTIN:
      case ORDER.TYPE.NEW:
        if (isUserLoggedIn) {
          dispatch({
            type: CONSTANTS.SET_CHECKOUT_FLOW,
            payload: CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER
          });
        } else if (hasUserInfo) {
          dispatch({
            type: CONSTANTS.SET_CHECKOUT_FLOW,
            payload: CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER
          });
        }
        break;
      default:
        break;
    }
  }, [isUserLoggedIn, hasUserInfo, orderType]);

  const { renderAmendedErrorPopup } = useAmendedTab();

  const { showModal, modalType } = showInfoModal;

  document.title = t('CHECKOUT_DOC_TITLE');
  useEffect(() => {
    dataLayerPush(
      getCheckoutDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: accessory?.selectedAccessories,
        location: window.location,
        order: productOrder
      }),
      true
    );
  }, []);

  const idCardLinkClicked = (hasNricFin = true) => {
    const idCardModalType = cisUser
      ? hasNricFin
        ? MODAL_TYPES.ID_CARD_NRIC_STAFF
        : MODAL_TYPES.ID_CARD_STAFF
      : MODAL_TYPES.ID_CARD_NRIC;

    setShowInfoModal({
      showModal: true,
      modalType: idCardModalType
    });
  };

  const proofLinkClicked = () => {
    setShowInfoModal({
      showModal: true,
      modalType: MODAL_TYPES.RES_DOCUMENT
    });
  };
  const kycLinkClicked = () => {
    setShowInfoModal({
      showModal: true,
      modalType: MODAL_TYPES.KYC
    });
  };

  const hideModal = () => {
    setShowInfoModal(prevState => ({
      ...prevState,
      showModal: false
    }));
  };

  const shouldShowClearUserPopup = (
    checkoutFlow: string,
    userInformation: UserInformation
  ) => {
    return (
      (checkoutFlow === CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER ||
        checkoutFlow === CONSTANTS.CHECKOUT_NEW_NUMBER ||
        checkoutFlow === CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER) &&
      !userInformation?.userDetails?.loginId
    );
  };

  const onValuesChange = <Tdata,>(values: Tdata): void => {
    // update redux state to retain the values
    // failsafe to avoid displaying invalid files
    if (checkout.resettingForm) {
      return;
    }
    const updatedValues = getUpdatedValues(values);

    dispatch({
      type: CONSTANTS.SET_CHECKOUT_FORM_DATA,
      payload: updatedValues
    });
  };

  const onFormSubmit = <Tdata,>(values: Tdata, navigateTo: string): void => {
    // to verify the formdata on successful submit
    // api call for form submission
    navigate(navigateTo);
  };

  const updateStepperTitles = (formConfig?: FormConfig) => {
    setStepperTitles(getStepperTilesData(formConfig));
  };

  const onErrorScenarioFound = error => {
    setErrorScenarioMessage(error);
  };

  const showStepper = () => {
    setHideStepper(true);
  };

  const goToOrderSummary = () => {
    const callback = (newBarId: string) => {
      const order = cart?.order;
      const hasDocument = checkout.documentUpload?.length > 0;
      const { customerId, barId } = getAccountBillingInfo({
        checkout,
        delivery,
        userInformation,
        productOrder,
        newBarId
      });
      const isBackOrPreOrder =
        selectedProduct?.isBackOrder || selectedProduct?.isPreOrder;
      const deliveryTimeSlotValue = simOnlyeSIMOrder
        ? getSimOnlyEsimDeliveryPayload()
        : {};
      const fulfilmentRequestParams = getFulfilmentParams({
        address: {},
        addressId: '',
        productOrder,
        selectedTimeSlot: null,
        userInfo: userInformation,
        simOnlyPlan: isSimOnlyWithoutDevice(selectedPlan, selectedProduct),
        hasDocument,
        isNewSimAdded: !!order?.newlyAddedSimDetails?.simDetails,
        order: cart.order,
        selectedFulfilmentMode:
          FULFILMENT_CONSTANTS.FULFILMENT_TYPES.NO_FULFILMENT,
        billingAccountId: barId,
        customerId: customerId,
        isBackOrPreOrder,
        itemSkuToOrderIdMapping: getItemSkuToOrderIdMapping(
          selectedProduct?.deviceCode,
          productOrder?.productOrderItemId
        ),
        ...deliveryTimeSlotValue
      });
      dispatch(
        fulFillmentActions.proceedToOrderSummary(fulfilmentRequestParams)
      );
    };

    dispatch(
      handleBillingAddress({
        checkout,
        fulfilmentState,
        delivery,
        userInformation,
        callback
      })
    );
  };

  const renderReconFlow = (): ReactNode => {
    return (
      <ReconFlow
        onGettingFormConfigs={updateStepperTitles}
        enableReinitialize={true}
        stackSpacing={2}
        isCisFlow={cisUser}
        onValuesChange={onValuesChange}
        idCardLinkClicked={idCardLinkClicked}
        onFormSubmit={onFormSubmit}
        billingPrefData={billPrefFlattened}
        handleCheckoutHistoryState={pushStateForBrowseBackHandling}
        goToOrderSummary={goToOrderSummary}
        isCISMyInfoFlow={changeCISMyInfoFlow}
      />
    );
  };

  const renderExistingNewFlow = (): ReactNode => {
    return (
      <ExistingNewFlow
        onGettingFormConfigs={updateStepperTitles}
        enableReinitialize={true}
        stackSpacing={2}
        isCisFlow={cisUser}
        onValuesChange={onValuesChange}
        idCardLinkClicked={idCardLinkClicked}
        kycLinkClicked={kycLinkClicked}
        onFormSubmit={onFormSubmit}
        billingPrefData={filteredBillPref}
        showStepper={showStepper}
        handleCheckoutHistoryState={pushStateForBrowseBackHandling}
        goToOrderSummary={goToOrderSummary}
        isCISMyInfoFlow={changeCISMyInfoFlow}
      />
    );
  };

  useEffect(() => {
    if (
      checkoutFlow === CONSTANTS.CHECKOUT_RECON ||
      checkoutFlow === CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER
    ) {
      showStepper();
    }
  }, [checkoutFlow]);

  const renderNewNewFlow = (
    prefilledData = {},
    isCompleteMyInfoData = false
  ): ReactNode => {
    return (
      <NewNewFlow
        onGettingFormConfigs={updateStepperTitles}
        enableReinitialize={true}
        isCisFlow={cisUser}
        stackSpacing={2}
        idCardLinkClicked={idCardLinkClicked}
        proofLinkClicked={proofLinkClicked}
        kycLinkClicked={kycLinkClicked}
        onValuesChange={onValuesChange}
        onFormSubmit={onFormSubmit}
        billingPrefData={filteredBillPref}
        errorScenariosData={errorScenariosFlattened}
        onErrorScenarioFound={onErrorScenarioFound}
        handleCheckoutHistoryState={pushStateForBrowseBackHandling}
        goToOrderSummary={goToOrderSummary}
        prefilledData={prefilledData}
        isCompleteMyInfoData={isCompleteMyInfoData}
      />
    );
  };

  const errorScenarioCtaAction = async ctaActionType => {
    if (ctaActionType === 'primary') {
      window.location.href = errorScenarioMessage?.primaryButtonLink;
    } else {
      window.location.href = errorScenarioMessage.secondaryButtonLink;
    }
  };

  const renderCISMyInfoFlow = (): ReactNode => {
    return (
      <CISMyInfoFlow
        onValuesChange={onValuesChange}
        billingPrefData={billPrefFlattened}
        onFormSubmit={onFormSubmit}
        errorScenariosData={errorScenariosFlattened}
        onErrorScenarioFound={onErrorScenarioFound}
        goToOrderSummary={goToOrderSummary}
        onGettingFormConfigs={updateStepperTitles}
        handleCheckoutHistoryState={pushStateForBrowseBackHandling}
      />
    );
  };

  const renderEKYCFlow = (): ReactNode => {
    if (!hasESimOrder && !isCompleteMyInfoData) {
      const prefilledData = getPreFilledCheckoutData(
        customerMyInfoResponse?.ImplCustomerInfoResponse
      );

      return renderNewNewFlow(prefilledData, !isCompleteMyInfoData);
    }
    return (
      <RESMyInfoFlow
        onValuesChange={onValuesChange}
        billingPrefData={filteredBillPref}
        errorScenariosData={errorScenariosFlattened}
        onErrorScenarioFound={onErrorScenarioFound}
        onFormSubmit={onFormSubmit}
        goToOrderSummary={goToOrderSummary}
        onGettingFormConfigs={updateStepperTitles}
      />
    );
  };

  const showEKycMyInfoMisMatchModal = (): ReactNode => {
    return (
      isMyInfoMisMatch && (
        <MyInfoEKYCModal
          isModalOpen={isMyInfoMisMatch}
          modaltitle={t('EKYC_MYINFO_MISMATCH_TITLE')}
          modalContent={t('EKYC_MYINFO_MISMATCH_DESCRIPTION')}
          ctaText={t('EKYC_MYINFO_MISMATCH_NAVIGATE_BUTTON')}
          secondaryType={true}
          onClose={handleBackToHome}
          onConfirm={handleBackToHome}
        />
      )
    );
  };

  const renderGenericError = () => {
    if (
      isFeatureFlagEnabled(CP9_FLOW) &&
      Object.keys(CP_ERROR_CODES).includes(errorScenarioMessage.errorCode)
    ) {
      const { navigateTo } = getCreditLimitErrorProps(errorScenarioMessage);
      return (
        <CPModal errorData={errorScenarioMessage} navigateTo={navigateTo} />
      );
    }
    return (
      <GenericError
        title={errorScenarioMessage?.title}
        errorText={errorScenarioMessage?.errorText}
        typeLinkText={errorScenarioMessage?.ctaText}
        primaryButtonText={errorScenarioMessage?.primaryButtonText}
        secondaryButtonText={errorScenarioMessage?.secondaryButtonText}
        onPrimaryClick={() => {
          errorScenarioCtaAction('primary');
        }}
        onSecondaryClick={() => {
          errorScenarioCtaAction('secondary');
        }}
        footerList={errorScenarioMessage?.footerList}
      />
    );
  };

  const renderFlow = () => {
    switch (checkoutFlow) {
      case CONSTANTS.CHECKOUT_RECON: {
        return renderReconFlow();
      }
      case CONSTANTS.CHECKOUT_EXISTING_NEW_CUSTOMER: {
        return renderExistingNewFlow();
      }
      case CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER: {
        if (infoLoading || isFromMyInfoWithError) {
          return null;
        }
        if (userCISMyInfoAvailable) {
          return renderCISMyInfoFlow();
        }
        if (
          (hasESimOrder || !manualFormEnabled) &&
          !isFromMyInfo &&
          !customerMyInfoResponse &&
          !isFromMyInfoWithError
        ) {
          return navigate(`/${navigation.USER_IDENTITY_CHECK}`, {
            replace: true
          });
        }

        if (customerMyInfoResponse) {
          const eKYCFailed = !!customerMyInfoResponse?.ImplCustomerInfoResponse
            ?.idTypeMismatch;
          return eKYCFailed ? showEKycMyInfoMisMatchModal() : renderEKYCFlow();
        } else {
          return renderNewNewFlow();
        }
      }
      default: {
        return null;
      }
    }
  };

  const handleBackToHome = () => {
    setSingpassCancel(false);
    setIsMyInfoMisMatch(false);
    dispatch({
      type: CONSTANTS.CHECKOUT_CLEAR_CUSTOMER_MY_INFO
    });
    dispatch({
      type: CONSTANTS.CHECKOUT_RESET_VERIFICATION_DATA
    });
    navigate(`/${navigation.SHOPPING_CART_PAGE}`, { replace: true });
  };

  return (
    <>
      {errorScenarioMessage ? (
        renderGenericError()
      ) : (
        <Spacing top={2}>
          <Grid>
            {(hideStepper || customerMyInfoState?.isLoading) && (
              <>
                <Stepper
                  totalSteps={stepperTitles?.length}
                  activeStep={1}
                  titles={stepperTitles}
                />
                <Spacing top={3} bottom={5}>
                  <Text type="pageTitle">{t('CHECK_OUT_NN_FORM_TITLE')}</Text>
                </Spacing>
              </>
            )}
            <Spacing top={1} bottom={8}>
              <Row>
                <Column noGutter xs={12} md={7}>
                  {renderFlow()}
                </Column>
              </Row>
            </Spacing>
          </Grid>
        </Spacing>
      )}
      <InformationModal
        type={modalType}
        showModal={showModal}
        hideModal={hideModal}
      />
      {renderBrowseBackModal()}
      {singpassCancel && (
        <MyInfoEKYCModal
          isModalOpen={singpassCancel}
          modaltitle={t('EKYC_MYINFO_MISMATCH_TITLE')}
          modalContent={t('EKYC_CANCEL_DESCRIPTION')}
          ctaText={t('EKYC_CANCEL_BUTTON')}
          secondaryType={true}
          onClose={handleBackToHome}
          onConfirm={handleBackToHome}
        />
      )}
      {customerMyInfoState?.isError && renderError()}
      {renderAmendedErrorPopup()}
      {(auth.isLoading || customerMyInfoState?.isLoading) && (
        <LoadingOverlayComponent />
      )}
    </>
  );
};

export default Checkout;
